<template>
    <div class="p2 bg-color-white col-12 border-box dealer-header">
      <img class="dealer-logo" :src="singleDealer.dealerLogo" >
  </div>
</template>

<script>

export default {
  name: 'DealerHeader',
  props: {
   singleDealer: Object
  }
}
</script>

<style>
  .dealer-logo{
    object-fit: contain; 
    height: 50px;
    display: block; 
    margin: 0 auto;
  }
</style>