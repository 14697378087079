<template>
  <div class="rsf-select-wrapper relative z1">
    <svg :style="`transform: rotate(180deg) translateY(50%)`" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <select required @change="updateDealer(dealer)" v-model="dealer" :class="`rsf-select color-black open-sans-regular pointer ${dealer === '' ? '' : ''}`">
      <option isselected disabled :value="''">{{$t('rsf_dealer_select_prompt')}}</option>
      <option v-for="dealer in dealers" :key="dealer.id" :value="dealer">{{ dealer.name }}</option>
    </select>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'DealerSelect',
  props: {
    updateDealer: Function,
    selectedCountry: Object,
    selectedState: Object
  },
  data() {
    return {
      dealers: [],
      dealer: ''
    }
  },
  mounted() {
    this.fetchDealerData();
  },
  methods: {
    fetchDealerData() {
      let that = this
      let country = { 
        "filter_country_isocode": that.selectedCountry.isocode2,  
        "filter_country_state": that.selectedState.state
      }
      axios
        .post(process.env.VUE_APP_RSF_API + "/public-api/dealers/list-dealers/json", country)
        .then(function (response) {
          that.dealers = response.data.data
          // that.updateDealer(that.dealers[0]);
          // that.dealer = that.dealers[0]
        })
        .catch(function (error) {
          // window.alert('Ooops... something wnet wrrong here! We are sorry.');
        });

    }
  },
  watch: {
    selectedCountry() {
      this.fetchDealerData();
    },
    'selectedState.state': function(){
      this.fetchDealerData();
    }
  }
}
</script>