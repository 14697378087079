<template>
  <div
    v-if="showModal"
    :class="`myreefer-popup myreefer-prdouct-detail-popup story_atom stb_modal relative border-box p2 ${showModal ? 'stb_modal_active' : ''} ${exitModal ? 'myreefer-popup-exit-animation' : ''}`"
    @click="closeModal">


    <div @click.stop="" class="mobile-product-lightbox-selection flex items-center justify-between bg-color-black p2">
      <!-- PRICES -->
      <div v-show="selectedCountry.isocode2 != 'XX'">
            <!-- DISCOUNT -->
            <div  v-if="product.prices.isDiscounted">
              <p class="open-sans-semibold h4 color-3" v-if="product.prices.isDiscounted">
                {{ 
                  formatCurrency(product.prices.currency, (parseFloat(product.prices.discountprice)))
                }}
                <span v-if="product.selectionType == 'iterable'" class="open-sans-regular h6 ml1 color-3" style="margin-left: 3px">
                  {{ 
                    $t('rsf_productCard_perUn') 
                  }}
                </span>
              </p>
              <p class="h6 crossed open-sans-semibold color-white">
                {{ 
                  formatCurrency(product.prices.currency, product.prices.retailprice) 
                }}
              </p>
            </div>

            <!-- RETAIL -->
            <div v-if="!product.prices.isDiscounted" class="mb2 pb1">
              <p class="open-sans-semibold h4 color-3">
                {{ 
                  formatCurrency(product.prices.currency, product.prices.retailprice) 
                }}
                <span v-if="product.selectionType == 'iterable'" class="open-sans-regular h6 ml1 color-3">
                  {{
                    $t('rsf_productCard_perUn')
                  }}
                </span>
              </p>
            </div>
          </div>


      <!-- Amount Select -->
      <div class="myReefer-product-card-amount-select-wrapper relative mx0" v-if="product.selectionType == 'iterable'">
        <svg :class="`chevron ${product.amount > 0 ? 'svg-color-3' : 'svg-color-white'}`" width="24" height="24"
          viewBox="0 0 24 24" fill="none">
          <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <select :class="`myReefer-product-card-amount-select pointer ${product.amount > 0 ? 'color-3' : 'color-white'}`"
          v-model="product.amount" @change="updateSelectedProducts(product)">
          <option class="bg-color-black" :value="0">0</option>
          <option class="bg-color-black" v-for="index in Number(product.maxAmountPerItem)" :value="index">{{ index }}</option>
          <!-- <option :value="2">2</option> -->
        </select>
      </div>

      <!-- Toggle Select -->
      <button
        :class="`myReefer-product-select-button block relative pointer ${product.amount > 0 ? 'color-3' : 'color-1'}`"
        @click="toggleProductSelect(product)" v-if="product.selectionType == 'toggleable'">
        <svg v-if="product.amount == 0" width="16" height="16" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.6148 7.41483H8.98517V1.78517C8.98517 1.57693 8.90245 1.37722 8.7552 1.22997C8.60795 1.08272 8.40824 1 8.2 1C7.99176 1 7.79205 1.08272 7.6448 1.22997C7.49755 1.37722 7.41483 1.57693 7.41483 1.78517V7.41483H1.78517C1.57693 7.41483 1.37722 7.49755 1.22997 7.6448C1.08272 7.79205 1 7.99176 1 8.2C1 8.40824 1.08272 8.60795 1.22997 8.7552C1.37722 8.90245 1.57693 8.98517 1.78517 8.98517H7.41483V14.6148C7.41483 14.8231 7.49755 15.0228 7.6448 15.17C7.79205 15.3173 7.99176 15.4 8.2 15.4C8.40824 15.4 8.60795 15.3173 8.7552 15.17C8.90245 15.0228 8.98517 14.8231 8.98517 14.6148V8.98517H14.6148C14.8231 8.98517 15.0228 8.90245 15.17 8.7552C15.3173 8.60795 15.4 8.40824 15.4 8.2C15.4 7.99176 15.3173 7.79205 15.17 7.6448C15.0228 7.49755 14.8231 7.41483 14.6148 7.41483Z"
            fill="#1867FF" />
        </svg>
        <svg v-if="product.amount > 0" width="16" height="16" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M1 8L5.875 13L14 3" stroke="#00BF7A" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>

        <span class="h5 open-sans-bold">{{ product.amount > 0 ? $t('rsf_product_selected') : $t('rsf_product_select')
        }}</span>
      </button>
    </div>

    <div @click.stop="" class="content-wrapper max-width-3 col-12 pt4 bg-color-white border-box"
      style="border-radius: 8px">
      <a class="myreefer-close-btn" @click="closeModal">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="19.6867" height="2" rx="1"
            transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.15625 1.58594)" fill="black" />
          <rect x="15.4902" y="1.58594" width="19.6867" height="2" rx="1" transform="rotate(135 15.4902 1.58594)"
            fill="black" />
        </svg>
      </a>

      <div class="sm-flex">
        <div class="sm-col-6 col-12 px1 border-box flex flex-column justify-between">
          <div>
            <div>
              <div :class="`rsf-best-amount-badge h6 open-sans-semibold w-fit-content`"
                v-if="Number(product.bestUnitActive) > 0">
                {{ $t('rsf_productCard_best') }} {{ product.bestUnitAmount }} {{ product.bestUnitAmount == '1' ? $t('rsf_productCard_units_singular') : $t('rsf_productCard_units_plural') }}</div>
            </div>
            <div :class="`rsf-best-amount-badge h6 open-sans-semibold w-fit-content`" v-if="product.badgeActive">{{
                product.badgeContent
            }}
            </div>
            <img class="col-12 cover myreefer-product-lightbox-img" :src="activeImg" />
          </div>
          <div class="grid-3-col gap1 px2 pt2 pb2 mb1">
            <img @click="selectImg(img)" v-for="img in product.detailsPopUpImages" :key="img"
              :class="`col-12 prdouct-lightbox-thumbnail ${activeImg == img ? 'active' : ''}`" :src="img" />
          </div>
        </div>
        <div class="sm-col-6 col-12 flex flex-column justify-between sm-pb0 pb4">
          <div class="pb2">
            <h2 class="px2">{{ cleanModelName }}</h2>
            <!-- <p class="center h5 color-13 open-sans-regular mb2">{{ product.Color && product.Color != 'no-color' ? product.Color : '' }} {{ product.additionalProductData.LengthMetric ? product.additionalProductData.LengthMetric + 'cm' : '' }}</p> -->
            <p class="h5 color-13 open-sans-regular mb2 px2">{{ product.SubHeadline }}</p>
            <div class="py2 px2 myreefer-product-description"
              v-html="product.additionalProductData.ProductDescription"></div>
          </div>
          <div @click.stop="" class="product-lightbox-selection flex items-center justify-between bg-color-black p2">

            <div v-show="selectedCountry.isocode2 != 'XX'">
            <!-- DISCOUNT -->
            <div  v-if="product.prices.isDiscounted">
              <p class="open-sans-semibold h4 color-3">
                {{ 
                  formatCurrency(product.prices.currency, (parseFloat(product.prices.discountprice)))
                }}
                <span v-if="product.selectionType == 'iterable'" class="open-sans-regular h6 ml1 color-3" style="margin-left: 3px">
                  {{ 
                    $t('rsf_productCard_perUn') 
                  }}
                </span>
              </p>
              <p class="h6 crossed open-sans-semibold color-white">
                {{ 
                  formatCurrency(product.prices.currency, product.prices.retailprice) 
                }}
              </p>
            </div>

            <!-- RETAIL -->
            <div v-if="!product.prices.isDiscounted" class="mb2 pb1">
              <p class="open-sans-semibold h4 color-3">
                {{ 
                  formatCurrency(product.prices.currency, product.prices.retailprice) 
                }}
                <span v-if="product.selectionType == 'iterable'" class="open-sans-regular h6 ml1 color-3">
                  {{
                    $t('rsf_productCard_perUn')
                  }}
                </span>
              </p>
            </div>
          </div>

            <div v-show="selectedCountry.isocode2 == 'XX'"></div>


            <!-- Amount Select -->
            <div class="myReefer-product-card-amount-select-wrapper relative mx0"
              v-if="product.selectionType == 'iterable'">
              <svg :class="`chevron ${displayAmount > 0 ? 'svg-color-3' : 'svg-color-white'}`" width="24" height="24"
                viewBox="0 0 24 24" fill="none">
                <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <!-- TODO: Proper Condition Check and synchronizing of lightbox & product amount -->
              <select
                :class="`myReefer-product-card-amount-select pointer ${displayAmount > 0 ? 'color-3' : 'color-white'}`"
                v-model="modalDisplayAmount"
                @change="updateDisplayAmount(modalDisplayAmount); checkIfYouCanSelectproduct(product)">
                <option class="bg-color-black" :value="0">0</option>
                <option class="bg-color-black" v-for="index in Number(product.maxAmountPerItem)" :value="index">{{ index }}</option>
                <!-- <option :value="2">2</option> -->
              </select>
            </div>

            <!-- Toggle Select -->
            <button
              :class="`myReefer-product-select-button block relative pointer ${displayAmount > 0 ? 'color-3' : 'color-1'}`"
              @click="toggleProductSelect(product)" v-if="product.selectionType == 'toggleable'">
              <svg v-if="displayAmount == 0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.6148 7.41483H8.98517V1.78517C8.98517 1.57693 8.90245 1.37722 8.7552 1.22997C8.60795 1.08272 8.40824 1 8.2 1C7.99176 1 7.79205 1.08272 7.6448 1.22997C7.49755 1.37722 7.41483 1.57693 7.41483 1.78517V7.41483H1.78517C1.57693 7.41483 1.37722 7.49755 1.22997 7.6448C1.08272 7.79205 1 7.99176 1 8.2C1 8.40824 1.08272 8.60795 1.22997 8.7552C1.37722 8.90245 1.57693 8.98517 1.78517 8.98517H7.41483V14.6148C7.41483 14.8231 7.49755 15.0228 7.6448 15.17C7.79205 15.3173 7.99176 15.4 8.2 15.4C8.40824 15.4 8.60795 15.3173 8.7552 15.17C8.90245 15.0228 8.98517 14.8231 8.98517 14.6148V8.98517H14.6148C14.8231 8.98517 15.0228 8.90245 15.17 8.7552C15.3173 8.60795 15.4 8.40824 15.4 8.2C15.4 7.99176 15.3173 7.79205 15.17 7.6448C15.0228 7.49755 14.8231 7.41483 14.6148 7.41483Z"
                  fill="#1867FF" />
              </svg>
              <svg v-if="displayAmount > 0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 8L5.875 13L14 3" stroke="#00BF7A" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>

              <span class="h5 open-sans-bold">{{ displayAmount > 0 ? $t('rsf_product_selected') :
                  $t('rsf_product_select')
              }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductLightBox',
  props: {
    closeModal: Function,
    showModal: Boolean,
    exitModal: Boolean,
    product: Object,
    updateSelectedProducts: Function,
    formatCurrency: Function,
    selectedCountry: Object,
    toggleProductSelect: Function,
    checkIfYouCanSelectproduct: Function,
    displayAmount: Number,
    updateDisplayAmount: Function,
    discountEventActive: Boolean, 
    discountEvent: Object,

  },
  data() {
    return {
      activeImg: '',
      modalDisplayAmount: 0
    }
  },
  mounted() {
    this.activeImg = this.product.detailsPopUpImages[0];
    this.modalDisplayAmount = this.product.amount
  },
  methods: {
    selectImg(img) {
      this.activeImg = img
    },
  },
  computed: {
    cleanModelName(){
        return this.product.Model.replace('(add-on)', '')
    },
  },
  watch: {
    'product.amount'() {
      this.$forceUpdate();
    },
    displayAmount() {
      this.modalDisplayAmount = this.displayAmount
    }
  },

}
</script>
