<template>
    <div class="myreefer-tank-selection bg-color-11 pt3 md-pt4 px2" style="min-height: 100vh">
        <DiscountEventBannerBig v-if="discountEventActive" :discountEvent="discountEvent" />
        <div class="max-width-4 mx-auto overflow-hidden" style="padding: 0 1px">
            <h1 class="h1 center helvetica-bold color-black mb4" @click="toggleSelectedProduct('Hello')">
                {{ $t('rsf_myReefer_select_title') }}</h1>
            <FilterSlider :updateUpperCap="updateUpperCap" :updateLowerCap="updateLowerCap" :measurement="measurement" />
        </div>
        <CustomSwitch :selectedColor="selectedColor" :updateCabinetColor="updateCabinetColor" />
        <div :class="`flex justify-center relative max-width-5 mx-auto mb4 tankselection-category-flex ${showBundles ? 'op100' : 'op0'}`" style="transition: .5s">
            <div :class="`${dataDiscountBundles.length == 0 || dataNormalBundles.length == 0 ? 'col-12' : 'col-6'} ${dataNormalBundles.length == 0 ? 'hide' : ''}`">
                <h3 v-if="!(dataDiscountBundles.length == 0 || dataNormalBundles.length == 0)" class="tankselection-category-heading mb4 center lg-h2">{{ $t('rsf_bundle_category_heading_build_your_own') }}</h3>
                <CategoryGrid 
                    v-if="dataFetched" :bundle="dataNormalBundles" 
                    :selectedProducts="selectedProducts"
                    :toggleSelectedProduct="toggleSelectedProduct"
                    :computedHiCap="computedHiCap"
                    :computedLowCap="computedLowCap" 
                    :selectTank="selectTank" 
                    :isDiscountedBundle="false"
                    :formatCurrency="formatCurrency" 
                    :selectedColor="selectedColor" 
                    :selectedCountry="selectedCountry"
                    :measurement="measurement" 
                    :otherBundles="dataDiscountBundles"
                    :showTag="!(dataDiscountBundles.length == 0 || dataNormalBundles.length == 0)"
                />
            </div>
            <div v-if="!(dataDiscountBundles.length == 0 || dataNormalBundles.length == 0)" :class="`mt4 mb4 relative myreefer-category-divider-wrapper`">
                <div class="rsf-myreefer-category-divider"></div>
            </div>
            <div :class="`${dataDiscountBundles.length == 0 || dataNormalBundles.length == 0 ? 'col-12' : 'col-6'} ${dataDiscountBundles.length == 0 ? 'hide' : ''}`">
                <h3 v-if="!(dataDiscountBundles.length == 0 || dataNormalBundles.length == 0)" class="tankselection-category-heading mb4 center lg-h2">{{ $t('rsf_bundle_category_heading_plug_and_play') }}</h3>
                <CategoryGrid 
                    v-if="dataFetched" 
                    :bundle="dataDiscountBundles" 
                    :selectedProducts="selectedProducts"
                    :toggleSelectedProduct="toggleSelectedProduct" 
                    :computedHiCap="computedHiCap"
                    :computedLowCap="computedLowCap" 
                    :selectTank="selectTank" 
                    :isDiscountedBundle="true"
                    :formatCurrency="formatCurrency" 
                    :selectedColor="selectedColor" 
                    :selectedCountry="selectedCountry"
                    :measurement="measurement" 
                    :otherBundles="dataNormalBundles"
                    :showTag="!(dataDiscountBundles.length == 0 || dataNormalBundles.length == 0)"
                />
            </div>
        </div>
    </div>
</template>
  
<script>

import FilterSlider from './FilterSlider.vue'
import CategoryGrid from './CategoryGrid.vue'
import CustomSwitch from './CustomSwitch.vue'
import DiscountEventBannerBig from './DiscountEventBannerBig.vue'
import Loader from './Loader.vue'
import { nextTick } from 'vue'

export default {
    
    name: 'TankSelection',
    created() {
        this.resetTank();
        this.resetSelectedProducts();
        this.resetImgOverlays();
    },
    async mounted() {
        if (!this.selectedCountry.isocode2) {
            this.$router.push('/');
            return;
        }

        await nextTick();
        this.formatCardHeight();

        this.fetchData();
        
        window.scrollTo(0, 0);
    },
    methods: {
        formatCardHeight(){
            const cards = document.getElementsByClassName('rsf-bundle-card')
            let tallest = 0
            for(let i = 0; i < cards.length; i++){
                if(cards[i].offsetHeight > 0){
                    cards[i].style.height = 'auto'
                    tallest = cards[i].offsetHeight
                }
            }
            for(let j = 0; j < cards.length; j++){
                cards[j].style.height = tallest + 'px'
            }

        }
    },
    watch: {
        async dataFetched(newValue){
            if(!newValue) return;
            await nextTick();
            setTimeout(() => {
                this.formatCardHeight();
                this.setShowBundles(true)
            }, 500)
        }
    },

    components: {
    FilterSlider,
    CategoryGrid,
    CustomSwitch,
    DiscountEventBannerBig,
    Loader
},
    props: {
        selectedProducts: Array,
        measurement: String,
        toggleSelectedProduct: Function,
        updateUpperCap: Function,
        updateLowerCap: Function,
        computedHiCap: Number,
        computedLowCap: Number,
        selectTank: Function,
        dataNormalBundles: Array,
        dataDiscountBundles: Array,
        formatCurrency: Function,
        fetchData: Function,
        dataFetched: Boolean,
        loadingBundles: Boolean,
        selectedColor: String,
        updateCabinetColor: Function,
        resetTank: Function,
        resetSelectedProducts: Function,
        resetImgOverlays: Function,
        selectedCountry: Object,
        discountEventActive: Boolean, 
        discountEvent: Object,
        showBundles: Boolean,
        setShowBundles: Function
    },
}
</script>
  