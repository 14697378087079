<template>
  <div v-if="showModal"
    :class="`myreefer-popup myreefer-prdouct-detail-popup story_atom stb_modal relative border-box p2 ${showModal ? 'stb_modal_active' : ''} ${exitModal ? 'myreefer-popup-exit-animation' : ''}`"
    @click="closeModal">
    <div @click.stop="" class="content-wrapper max-width-3 col-12 pt4 bg-color-white border-box"
      style="border-radius: 8px">
      <a class="myreefer-close-btn pointer" @click="closeModal">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="19.6867" height="2" rx="1"
            transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.15625 1.58594)" fill="black" />
          <rect x="15.4902" y="1.58594" width="19.6867" height="2" rx="1" transform="rotate(135 15.4902 1.58594)"
            fill="black" />
        </svg>
      </a>

      <div class="sm-flex">
        <div class="sm-col-6 col-12 px1 border-box">
          <img class="col-12 contain myreefer-product-lightbox-img pb3" style="object-fit:contain"
            :src="selectedColor == 'Black' ? product.colorfulListImages.blackImage : product.colorfulListImages.whiteImage" />
        </div>
        <div class="sm-col-6 col-12 flex flex-column justify-between pb3">
          <h2 class="px2 helvetica-bold">{{ product.BundleName }}</h2>
          <div class="py2 px2 myreefer-product-description" v-html="product.BundleDescription"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TankLightBox',
  props: {
    closeModal: Function,
    showModal: Boolean,
    product: Object,
    selectedColor: String,
    exitModal: Boolean
  },
  data() {
    return {
      activeImg: ''
    }
  },
  computed: {
    formattedDescription() {
      let text = [];
      text = this.product.BundleDescription.split('<br>')
    }
  },
  mounted() {
    this.formatDescription();
  },
  methods: {
    formatDescription() {
      if (this.product.BundleDescription) {
        if (this.product.BundleDescription.includes('<br />')) {
          let text = [];
          let firstBlock = '';
          let lastBlock = '';

          text = this.product.BundleDescription.split('<br />');

          let firstEl = text[0];
          text.splice(0, 1);

          firstEl = firstEl.split(`class="left specs-col1">`)
          firstBlock = firstEl[0] + `class="left specs-col1">`;
          firstEl.splice(0, 1);
          text = firstEl.concat(text)
          let lastEl = text[text.length - 1];

          // lastEl = lastEl.split('</div>');

          const [key, ...rest] = lastEl.split('</div>')
          const value = rest.join('</div>')

          lastBlock = value;

          text.splice(text.length - 1, 1);
          text = text.concat(lastEl[0])

          for (let i = 0; i < text.length; i++) {
            text[i] = '<div>' + text[i] + '</div>';
          }

          const res = Array.from({ length: text.length / 2 }, (_, i) => text[2 * i] + text[2 * i + 1]);

          for (let j = 0; j < res.length; j++) {
            res[j] = '<div class="flex justify-between">' + res[j] + '</div>';
          }

          this.product.BundleDescription = '<div class="formatted-desc-field">' + firstBlock + '<div class="myreefer-popup-description-table">' + res.join('') + '</div>' + lastBlock + '</div>';
        }
      }
    }
  }
}
</script>