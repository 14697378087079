<template>
    <div class="lg-flex flex-column overflow-hidden flex-wrap mb1" v-if="product.Category == 'Preselected Products'">
      
      <!-- fs 16 -> 14 -->
      <h3 class="open-sans-regular h5 lt-h6 nowrap">{{ product.amount }}x {{ product.Model }}</h3>
      <h4 class="h6 lg-h5 lt-h6 open-sans-regular color-13 nowrap"><span class="color-black open-sans-regular regular px1 cart-item-info-divider">{{ product.SubHeadline ? '|' : '' }}</span>{{ product.SubHeadline ? product.SubHeadline : '' }}</h4>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus';

export default {
  name: 'PlatinumCartItem',
  props: {
    product: Object,
  },
  created(){
    let that = this
    EventBus.$on('update_amount', (payload) => {
      if(that.product.RNumber == payload.RNumber){
        that.product.amount = payload.amount
        that.$forceUpdate();
      }
    })
  } 
}
</script>
