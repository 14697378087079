<template>
  <div class="myreefer-discount-event-banner-big mx-auto max-width-5 mb5">
    <div class="myreefer-discount-event-banner-box relative">
      <img class="myreefer-discount-event-banner-bg-img" :src="discountEvent.imagesBackgroundImageS3Path"  /> 
      <div class="px3 pb3 pt2 border-box relative z2">
        <div class="myreefer-discount-event-banner-topline h4 center color-1 mb2 mx-auto h5 bold">{{ discountEvent.labelHeadline }}</div>
        <h2 class="h1 color-white center mb2">{{ discountEvent.labelHeadline2 }}</h2>
        <p class="center h6 color-white op60">{{ discountEvent.labelHeadline3 }}</p>
      </div>
    </div>
    <div class="myreefer-discount-event-timer mx-auto mt2">
      <Countdown 
        :startDate="discountEvent.startingDatetime"
        :endDate="discountEvent.endingDatetime"
      />
    </div>
  </div>
</template>

<script>
import Countdown from './Countdown.vue';


export default {
    name: "DiscountEventBannerBig",
    props: {
        discountEvent: Object
    },
    components: { Countdown }
}
</script>