<template>
  <div class="cart-item-price-wrapper">
    <div v-if="isDiscounted" class="cart-item-price lg-flex-row-reverse flex-wrap justify-start items-baseline">
      <p class=" center open-sans-bold h5 ml1">
        {{ 
          formatCurrency(currency, (parseFloat(discountPrice) * amount))
        }}
      </p>
      <p class="color-6 h6 crossed right-align open-sans-regular">
        {{ 
          formatCurrency(currency, (parseFloat(retailPrice) * amount))
        }}
      </p>
    </div>
    <div v-if="!isDiscounted">
      <p class=" center open-sans-bold h5 ml1">
        {{ 
          formatCurrency(currency, parseFloat(retailPrice) * amount)
        }}
      </p>
    </div>   
  </div>
</template>

<script>

export default {
  name: 'ItemPrice',
  props: {
    discountPrice: String,
    retailPrice: String, 
    isDiscounted: Boolean, 
    discountEventActive: Boolean, 
    discountEvent: Object,
    currency: String,
    formatCurrency: Function,
    amount: Number 
  }
}
</script>