<template>
  <div v-if="!isDifferentColor" :class="`product-card-element flex flex-column ${isDifferentColor ? 'hide-product-card' : ''}`">
    <div :class="`rsf-compare-product-card bg-color-white pointer pb2 h100 border-box flex flex-column mb2 ${isSelected ? 'card-selected' : ''} ${invisibleCard ? 'invisible-prdouct-card' : ''} ${isDisabled ? 'card-disabled' : ''} ${product.hideProductStartCurrentStatus == '1' ? product.hideCSSClasses + ' myreefer-product-card-hide' : ''} rsf-card-interaction-animation`" style="border-radius: 8px;"
      @click="product.selectionType == 'toggleable' && product.hasFixedAmount !== '1' ? toggleProductSelect(product) : ''">
      <div
        :class="`flex ${Number(product.bestUnitActive) > 0 || product.badgeActive ? 'justify-between' : 'justify-end'} ${isDisabled ? 'pointer-events-none op0' : ''}`">
        <div :class="`rsf-best-amount-badge h6 open-sans-semibold`" v-if="Number(product.bestUnitActive) > 0">{{
            $t('rsf_productCard_best')
        }} {{ product.bestUnitAmount }} {{ product.bestUnitAmount == '1' ? $t('rsf_productCard_units_singular') : $t('rsf_productCard_units_plural') }}</div>
        <div :class="`rsf-best-amount-badge h6 open-sans-semibold`" v-if="product.badgeActive">{{ product.badgeContent
        }}
        </div>
        <button class="i-btn pointer p0" @click.stop="showModal = !showModal"><svg style="width: 24px"
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="9.75" stroke="#909CA5" stroke-width="1.5" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12 8.25C12.6904 8.25 13.25 7.69036 13.25 7C13.25 6.30964 12.6904 5.75 12 5.75C11.3096 5.75 10.75 6.30964 10.75 7C10.75 7.69036 11.3096 8.25 12 8.25ZM12 9.5C12.6041 9.5 13.108 9.92848 13.2246 10.4981C13.2413 10.5795 13.25 10.6637 13.25 10.75V17C13.25 17.6904 12.6904 18.25 12 18.25C11.3096 18.25 10.75 17.6904 10.75 17L10.75 12C10.0596 12 9.5 11.4404 9.5 10.75C9.5 10.0596 10.0596 9.5 10.75 9.5H12Z"
              fill="#909CA5" />
          </svg>
        </button>
      </div>
      <img v-show="imgLoaded" :class="`col-12 contain ${isDisabled ? 'op50' : ''}`" :src="product.ImagePath" @load="imgLoaded = true" />
      
      <div class="px2 border-box flex flex-column" style="flex: 1">

        <div :class="`${isDisabled ? 'op50' : '' }`">
          <h4 class="h3 helvetica-bold color-black center">{{ cleanModelName }}</h4>
          <!-- <p class="center h5 color-13 open-sans-regular mb2">{{ product.Color && product.Color != 'no-color' ? product.Color : '' }}{{product.Color != 'no-color' && product.additionalProductData.LengthMetric ? ' | ' : ''}}{{ product.additionalProductData.LengthMetric ? product.additionalProductData.LengthMetric + 'cm' : '' }}</p> -->
          <p class="center h5 color-13 open-sans-regular mb2">{{ product.SubHeadline }}</p>
        </div>

        <!-- Show this block if product is disabled -->
        <div v-if="isDisabled" class="h100 bg-color-16 flex flex-column justify-center p2 border-box" style="border-radius: 8px;">
          <svg class="mx-auto mb1 svg-color-13" width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0791 0.979592C10.6266 0.875315 7.27335 2.14314 4.75344 4.50555C2.23354 6.86796 0.752242 10.1325 0.633789 13.5846C0.752242 17.0366 2.23354 20.3012 4.75344 22.6636C7.27335 25.026 10.6266 26.2938 14.0791 26.1896C17.5316 26.2938 20.8849 25.026 23.4048 22.6636C25.9247 20.3012 27.406 17.0366 27.5244 13.5846C27.406 10.1325 25.9247 6.86796 23.4048 4.50555C20.8849 2.14314 17.5316 0.875315 14.0791 0.979592ZM2.73462 13.5846C2.77211 10.9659 3.82689 8.4646 5.67578 6.60982L20.953 21.9879C18.9311 23.376 16.5316 24.1094 14.0791 24.0887C11.1836 24.1933 8.36453 23.1469 6.23854 21.1784C4.11255 19.2099 2.85271 16.4796 2.73462 13.5846ZM22.4824 20.5593L7.20519 5.18125C9.22707 3.79311 11.6266 3.05974 14.0791 3.08042C16.9746 2.97581 19.7937 4.0222 21.9197 5.99071C24.0457 7.95922 25.3055 10.6896 25.4236 13.5846C25.3861 16.2032 24.3313 18.7045 22.4824 20.5593Z" fill="black"/>
          </svg>
          <p class="open-sans-bold h7 sm-h6 center ">{{ $t('myreefer_prdouct_disabled') }}</p>
        </div>

        <div class="flex flex-column justify-between" style="flex: 1">

          <!-- PRICES -->
          <div v-show="selectedCountry.isocode2 != 'XX' && !isDisabled">
            <!-- DISCOUNT -->
            <div  v-if="product.prices.isDiscounted" class="mb2 pb1">
              <p class="open-sans-semibold h4 center">
                {{ 
                  formatCurrency(product.prices.currency, (parseFloat(product.prices.discountprice)))
                }}
                <span v-if="product.selectionType == 'iterable'" class="open-sans-regular color-black h6" style="margin-left: 3px">
                  {{ 
                    $t('rsf_productCard_perUn') 
                  }}
                </span>
              </p>
              <p class="color-6 h5 crossed open-sans-regular center">
                {{ 
                  formatCurrency(product.prices.currency, product.prices.retailprice) 
                }}
              </p>
            </div>

            <!-- RETAIL -->
            <div v-if="!product.prices.isDiscounted" class="mb2 pb1">
              <p class="open-sans-semibold h4 center">
                {{ 
                  formatCurrency(product.prices.currency, product.prices.retailprice) 
                }}
                <span v-if="product.selectionType == 'iterable'" class="open-sans-regular color-13 h6 ml1">
                  {{
                    $t('rsf_productCard_perUn')
                  }}
                </span>
              </p>
            </div>
          </div>

          <!-- Amount Select -->
          <div v-show="!isDisabled" :class="`myReefer-product-card-amount-select-wrapper relative mx-auto ${product.hasFixedAmount == '1' ? 'inactive-input' : ''}`"
            v-if="product.selectionType == 'iterable'">
            <svg :class="`chevron ${product.amount > 0 ? 'svg-color-3' : 'svg-color-1'}`" width="24" height="24"
              viewBox="0 0 24 24" fill="none">
              <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <select
              :class="`myReefer-product-card-amount-select pointer h4 ${product.amount > 0 ? 'color-3' : 'color-1'}`"
              v-model="displayAmount" @change="checkIfYouCanSelectproduct()">
              <option :value="0">0</option>
              <option v-for="index in Number(product.maxAmountPerItem)" :value="index">{{ index }}</option>
              <!-- <option :value="2">2</option> -->
            </select>
          </div>


          <!-- Toggle Select -->
          <button
            :class="`myReefer-product-select-button block relative pointer mx-auto ${product.amount > 0 ? 'color-3' : 'color-1'} ${product.hasFixedAmount == '1' && product.amount == 0 ? 'hide-select-input' : ''}`"
            v-if="product.selectionType == 'toggleable' && !isDisabled">
            <svg v-if="product.amount == 0" width="16" height="16" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.6148 7.41483H8.98517V1.78517C8.98517 1.57693 8.90245 1.37722 8.7552 1.22997C8.60795 1.08272 8.40824 1 8.2 1C7.99176 1 7.79205 1.08272 7.6448 1.22997C7.49755 1.37722 7.41483 1.57693 7.41483 1.78517V7.41483H1.78517C1.57693 7.41483 1.37722 7.49755 1.22997 7.6448C1.08272 7.79205 1 7.99176 1 8.2C1 8.40824 1.08272 8.60795 1.22997 8.7552C1.37722 8.90245 1.57693 8.98517 1.78517 8.98517H7.41483V14.6148C7.41483 14.8231 7.49755 15.0228 7.6448 15.17C7.79205 15.3173 7.99176 15.4 8.2 15.4C8.40824 15.4 8.60795 15.3173 8.7552 15.17C8.90245 15.0228 8.98517 14.8231 8.98517 14.6148V8.98517H14.6148C14.8231 8.98517 15.0228 8.90245 15.17 8.7552C15.3173 8.60795 15.4 8.40824 15.4 8.2C15.4 7.99176 15.3173 7.79205 15.17 7.6448C15.0228 7.49755 14.8231 7.41483 14.6148 7.41483Z"
                fill="#1867FF" />
            </svg>
            <svg v-if="product.amount > 0" width="16" height="16" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M1 8L5.875 13L14 3" stroke="#00BF7A" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>

            <span :class="`${(siteLanguage == 'de' || siteLanguage == 'fr') ? 'mobile-select-btn-small' : ''} h5 open-sans-bold`">{{ product.amount > 0 ? $t('rsf_product_selected') :
                $t('rsf_product_select')
            }}</span>
          </button>

        </div>
      </div>

    </div>

    <div v-if="(category.dividerElementsExisting)">

      <div class="mt4" v-if="category.dividerElements[0] && (myIndex == category.dividerElements[0].moduloNumber || myIndex == Number(category.dividerElements[0].moduloNumber) - 1) && !(category.dividerElements[0].hideDividerStartCurrentStatus == '1')">
        <p :class="`section-divider ${siteLanguage == 'fr' ? 'small-section-divider-mobile' : ''} ${category.dividerElements[0].hideDividerStartCurrentStatus == '1' ? category.dividerElements[0].hideCSSClasses + ' myreefer-product-card-hide' : ''}`">
          {{ category.dividerElements[0].name }}
        </p>
      </div>

      <div class="mt4" v-if="category.dividerElements[1] && (myIndex == category.dividerElements[1].moduloNumber || myIndex == Number(category.dividerElements[1].moduloNumber) - 1) && !(category.dividerElements[1].hideDividerStartCurrentStatus == '1') && category.products.length > 4" >
        <p :class="`section-divider ${siteLanguage == 'fr' ? 'small-section-divider-mobile' : ''} ${category.dividerElements[1].hideDividerStartCurrentStatus == '1' ? category.dividerElements[1].hideCSSClasses + ' myreefer-product-card-hide' : ''}`">
          {{ category.dividerElements[1].name }}
        </p>
      </div>

      <div class="mt4" v-if="category.dividerElements[2] && (myIndex == category.dividerElements[2].moduloNumber || myIndex == Number(category.dividerElements[2].moduloNumber) - 1) && !(category.dividerElements[2].hideDividerStartCurrentStatus == '1')">
        <p :class="`section-divider ${siteLanguage == 'fr' ? 'small-section-divider-mobile' : ''} ${category.dividerElements[2].hideDividerStartCurrentStatus == '1' ? category.dividerElements[2].hideCSSClasses + ' myreefer-product-card-hide' : ''}`">
          {{ category.dividerElements[2].name }}
        </p>
      </div>
    </div>

    <ProductLightBox 
      :showModal="showModal" 
      :closeModal="closeModal" 
      :exitModal="exitModal" 
      :product="product"
      :updateSelectedProducts="updateSelectedProducts" 
      :formatCurrency="formatCurrency"
      :selectedCountry="selectedCountry" 
      :toggleProductSelect="toggleProductSelect"
      :checkIfYouCanSelectproduct="checkIfYouCanSelectproduct" 
      :displayAmount="displayAmount"
      :updateDisplayAmount="updateDisplayAmount" 
    />
  </div>

</template>

<script>

import ProductLightBox from './ProductLightBox.vue'
import { EventBus } from '@/event-bus';

export default {
  name: 'ProductCard',
  props: {
    product: Object,
    productCategories: Array,
    selectedProducts: Array,
    toggleSelectedProduct: Function,
    updateSelectedProducts: Function,
    formatCurrency: Function,
    openModal: Function,
    category: Object,
    computeImageOverlays: Function,
    selectedColor: String,
    selectedCountry: Object,
    cardIndex: Number,
    siteLanguage: String,
  },
  components: {
    ProductLightBox
  },
  data() {
    return {
      showModal: false,
      exitModal: false,
      displayAmount: 0,
      imgLoaded: false,
      myIndex: 0
    }
  },
  created(){
    EventBus.$on('update_amount', (payload) => {
      if(payload.RNumber == this.product.RNumber){
        this.displayAmount = payload.amount
      }
    });

    EventBus.$on('get_card_index', (payload) => {
      this.checkForDividersAndCards();
      this.getCardIndex(payload);
    })
  },  
  mounted() {
    this.displayAmount = this.product.amount;

    this.product.hideProductStartCurrentStatus = this.product.hideProductStart;
    for (let i = 0; i < this.category.dividerElements.length; i++) {
      this.category.dividerElements[i].hideDividerStartCurrentStatus = this.category.dividerElements[i].hideDividerStart;
    }

    this.checkForDividersAndCards();
    this.getCardIndex();
  },
  computed: {
    isDisabled(){
      if(this.product.showHideProduct != '1'){
        return; 
      }

      let disabledCountries = this.product.hideProductISOcodes.split(',');

      for(let i = 0; i < disabledCountries.length; i++){
        if(disabledCountries[i].toLowerCase() == this.selectedCountry.isocode2.toLowerCase()){
          return true; 
        }
      }

      return false;
    },
    cleanModelName(){
        return this.product.Model.replace('(add-on)', '')
    },
    isSelected() {
      for (let i = 0; i < this.selectedProducts.length; i++) {
        if (this.selectedProducts[i].id == this.product.id) {
          this.displayAmount = this.product.amount
          return true
        }
      }
      this.displayAmount = 0;
      this.product.amount = 0;
      return false
    },
    invisibleCard() {
      if (this.product.RNumber == 'R99999') { return true; }
    },
    isDifferentColor(){
      if ((this.product.onlyVisibleWhenColorsMatched && this.product.Color == this.selectedColor) || !this.product.onlyVisibleWhenColorsMatched) {
        return false
      } else {
        return true;
      }
    }
  },
  methods: {
    getCardIndex(payload){
      let cleanArr = []

      if(payload){
        for(let i = 0; i < this.category.products.length; i++){
          if(this.category.products[i].Color == payload.color || this.category.products[i].Color == 'no-color'){
            cleanArr.push(this.category.products[i]);
          }
        }

        for(let j = 0; j < cleanArr.length; j++){
          if(this.product.id == cleanArr[j].id){
            this.myIndex = j+1;
          }
        }
      }else{
        for(let i = 0; i < this.category.products.length; i++){
          if(this.category.products[i].Color == this.selectedColor || this.category.products[i].Color == 'no-color'){
            cleanArr.push(this.category.products[i]);
          }
        }

        for(let j = 0; j < cleanArr.length; j++){
          if(this.product.id == cleanArr[j].id){
            this.myIndex = j+1;
          }
        }
      }



    },
    updateDisplayAmount(v) {
      this.displayAmount = v;
    },
    closeModal() {
      this.exitModal = true,
        setTimeout(() => {
          this.showModal = !this.showModal
          this.exitModal = false;
        }, 300)
    },
    toggleProductSelect() {
      if (this.displayAmount == 0) {
        this.displayAmount++
      }
      else if (this.displayAmount > 0) {
        this.displayAmount = 0
      }
      this.checkIfYouCanSelectproduct();
      this.$forceUpdate();
    },
    checkIfYouCanSelectproduct() {
      this.checkAmountDepend();
      if (!this.checkAmountDepend) { return; }
      this.checkForMaxProductQuantityPerCategory();
      if (this.product.resetQuantityAmountOfOtherProductsActive) {
        this.checkIfOtherProductsHaveToBeRemoved();
      }
      this.checkForDividersAndCards();
      if(this.product.amount == 0){
        this.checkIfOtherProductsNeedToBeHidden();
      }
      EventBus.$emit('get_card_index');

    },
    checkIfOtherProductsNeedToBeHidden(){
      if(this.product.toggleClass != '1'){return;}
      // check all products. if they have the hideCSSClass condition and have the same helper class as the elemnt that was removed, then hide the product. 
      for(let i = 0; i < this.productCategories.length; i++){
        for(let y = 0; y < this.productCategories[i].products.length; y++){
          if(this.productCategories[i].products[y].hideCSSClasses === this.product.selectedClasses){
            this.productCategories[i].products[y].hideProductStartCurrentStatus = '1';
          }
        }
      }

      for(let i = 0; i < this.productCategories.length; i++){
        for(let y = 0; y < this.productCategories[i].dividerElements.length; y++){
          if(this.productCategories[i].dividerElements[y].hideCSSClasses === this.product.selectedClasses){
            this.productCategories[i].dividerElements[y].hideDividerStartCurrentStatus = '1';
          }
        }
      }
    },
    checkIfOtherProductsNeedToBeHiddenForProduct(product){
      if(product.toggleClass != '1'){return;}
      // check all products. if they have the hideCSSClass condition and have the same helper class as the elemnt that was removed, then hide the product. 
      for(let i = 0; i < this.productCategories.length; i++){
        for(let y = 0; y < this.productCategories[i].products.length; y++){
          if(this.productCategories[i].products[y].hideCSSClasses === product.selectedClasses){
            this.productCategories[i].products[y].hideProductStartCurrentStatus = '1';
          }
        }
      }

      for(let i = 0; i < this.productCategories.length; i++){
        for(let y = 0; y < this.productCategories[i].dividerElements.length; y++){
          if(this.productCategories[i].dividerElements[y].hideCSSClasses === product.selectedClasses){
            this.productCategories[i].dividerElements[y].hideDividerStartCurrentStatus = '1';
          }
        }
      }
    },
    checkAmountDepend() {

      let linkedProducts = []

      //if product is not linked to other product, maybe other prdoucts in cart are linked to this one
      if (!this.product.amountDepend && this.selectedProducts.length > 0) {

        let removeCounter = 0;

        //check if a product in the cart has a connection to the new one
        for (let i = 0; i < this.selectedProducts.length; i++) {

          if (!this.selectedProducts[i].amountDepend) {
            continue;
          }

          if (this.product.RNumber == this.selectedProducts[i].amountDependOnRNumber && this.selectedProducts[i].amount > this.displayAmount) {

            //prdoucts are linked and new product has bigger amount than product in cart, so remove it from cart
            this.selectedProducts[i].amount = this.displayAmount;
            if(this.selectedProducts[i].amount == 0){
              this.selectedProducts.splice(i, 1);
            }else{
              EventBus.$emit('update_amount', ({
                RNumber: this.selectedProducts[i].RNumber,
                amount: this.displayAmount
              }))
            }

            this.$forceUpdate();
            removeCounter++;
          }

          //alert: "Adjusted quantity of linked product"
          if (removeCounter == 1) {
            this.openModal(
              this.$t('rsf_popup_alert_notice'),
              this.$t('rsf_product_card_dependAmount_alert_3')
            );
          }
          
          //alter: "Adjusted quantity of linked products"
          if (removeCounter > 1) {
            this.openModal(
              this.$t('rsf_popup_alert_notice'),
              this.$t('rsf_product_card_dependAmount_alert_3_plural')
            );
          }
        }
      }

      //loop through selected prdoucts and see if there is a linked product
      for (let x = 0; x < this.selectedProducts.length; x++) {
        if (this.product.amountDependOnRNumber == this.selectedProducts[x].RNumber) {
          linkedProducts.push(this.selectedProducts[x])
        }
      }

      //if no linked product was found, it is not allowed to select this product
      if (linkedProducts.length == 0 && this.product.amountDepend) {
        this.displayAmount = this.product.amount;
        this.openModal(
          this.$t('rsf_popup_alert_notice'),
          this.$t('rsf_product_card_dependAmount_alert_2')
        );
        return false;
      }

      //now loop through linkedProducts and check if conditions meet 
      for (let y = 0; y < linkedProducts.length; y++) {
        if (this.displayAmount <= linkedProducts[y].amount) {
          // everything is alright, move on
          continue;
        }

        //displayAmount is higher than the linked product amount, throw a notice
        //"You cannot select more products of this kind than its counterpart."
        this.displayAmount = this.product.amount;
        this.openModal(
          this.$t('rsf_popup_alert_notice'),
          this.$t('rsf_product_card_dependAmount_alert_1')
        );
        return false;
      }

      //everything is alright!
      return true;

    },
    checkForMaxProductQuantityPerCategory() {

      let category = {};
      for (let i = 0; i < this.productCategories.length; i++) {
        if (this.product.Category == this.productCategories[i].categoryname) {
          category = this.productCategories[i];
        }
      }

      //everything is fine, just update
      if (category.limitType == 'unlimited') {
        this.product.amount = this.displayAmount
        this.updateSelectedProducts(this.product)
        return true
      }

      if (category.limitType == 'and-limit' || category.limitType == 'or-limit') {
        let totalProductsAmount = 0;
        for (let x = 0; x < this.selectedProducts.length; x++) {
          //if our product is already in the selctedProducts array, dont take it inot account
          // if prdouct is in category, add its amount to totalprdouctsamount
          if (this.selectedProducts[x].id != this.product.id && this.selectedProducts[x].Category == category.categoryname) {
            totalProductsAmount = totalProductsAmount + this.selectedProducts[x].amount;
          }
        }
        totalProductsAmount = totalProductsAmount + this.displayAmount;

        if (totalProductsAmount > category.limitAmount) {
          this.displayAmount = this.product.amount;
          //open modal
          this.openModal(
            this.$t('rsf_popup_alert_notice'),
            this.$t('rsf_product_card_maxAmount_alert_1')
          )
        } else {
          this.product.amount = this.displayAmount;
          this.updateSelectedProducts(this.product);
        }
      }

      if (category.limitType == 'or-limit') {
        console.log('category type: or-limit');
      }

    },
    checkIfOtherProductsHaveToBeRemoved() {
      //check if this product is seelcted 

      let productsIsSelected = false;
      let productsKickedOut = 0;

      for (let i = 0; i < this.selectedProducts.length; i++) {
        if (this.product.RNumber == this.selectedProducts[i].RNumber) {
          productsIsSelected = true;
        }
      }

      if (!productsIsSelected) { 
        return;
      };

      for (let x = 0; x < this.product.resetQuantityAmountOfOtherProductsRNumbers.length; x++) {
        for (let z = 0; z < this.selectedProducts.length; z++) {
          if (this.product.resetQuantityAmountOfOtherProductsRNumbers[x] == this.selectedProducts[z].RNumber) {
            this.checkIfOtherProductsNeedToBeHiddenForProduct(this.selectedProducts[z])
            this.selectedProducts.splice(z, 1);
            this.computeImageOverlays();
            this.$forceUpdate();
            productsKickedOut++;

          }
        }
      }

      if (productsKickedOut > 0) {
        this.openModal(
          this.$t('rsf_popup_alert_notice'),
          this.$t('rsf_popup_alert_resetAmount_1')
        )
      }

    },
    checkForDividersAndCards() {
      // DIVIDER ELEMENTS
      //  hideCSSClasses
      //  hideDividerStart
      //  hideDividerStartCurrentStatus

      // PRDOUCTCARDS
      //  toggleClass
      //  hideProductStart
      //  hideCSSCLasses
      //  selectedClass
      //  hideProductStartCurrentStatus

      // if there are no selected prdoucts, hide all products & divider elements

      if (this.selectedProducts.length == 0) {
        for (let z = 0; z < this.category.products.length; z++) {
          if (this.category.products[z].hideProductStart == '1') {
            this.category.products[z].hideProductStartCurrentStatus = '1';
          }
        }
        for (let n = 0; n < this.category.dividerElements.length; n++) {
          if (this.category.dividerElements[n].hideDividerStart == '1') {
            this.category.dividerElements[n].hideDividerStartCurrentStatus = '1';
          }
        }
        return;
      }


      // if there are selected products
      for (let i = 0; i < this.selectedProducts.length; i++) {
        if (this.selectedProducts[i].toggleClass != '1') { continue; }

        // if this selectedPrdoucts has toggleClass == 1 ...

        // ... toggle the prdouct if it has the same class
        for (let x = 0; x < this.category.products.length; x++) {
          if (this.category.products[x].hideCSSClasses == this.selectedProducts[i].selectedClasses) {
            // wenn target-class gleich der hideClass ist, toggle element
            if (this.category.products[x].hideProductStartCurrentStatus == '1') {
              this.category.products[x].hideProductStartCurrentStatus = '0';
            }
          }
        }

        // ... toggle the divider element
        for (let y = 0; y < this.category.dividerElements.length; y++) {
          if (this.category.dividerElements[y].hideCSSClasses == this.selectedProducts[i].selectedClasses) {
            this.category.dividerElements[y].hideDividerStartCurrentStatus = '0';
          }
        }
      }
    }
  },

}
</script>
<style>
  .rsf-compare-product-card.card-disabled{
    background-color: rgba(255, 255, 255, 0.5);
    cursor:default;
    pointer-events: none;
  }
</style>
