<template>
  <router-link  :to="'/' + this.$i18n.locale + '/cart'" class="myReefer-shopping-cart-icon relative pointer">
    <div :class="`myReefer-shopping-cart-product-count color-white ${animate ? 'cart-count-animation' : ''}`">
      <span class="myreefer-shopping-cart-number">{{productCount}}</span>
    </div>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.5" cy="36.5" r="1.5" fill="black" stroke="black" stroke-width="2" />
      <circle cx="29.5" cy="36.5" r="1.5" fill="black" stroke="black" stroke-width="2" />
      <path
        d="M6 14H9.68619C9.88844 14 10.0708 14.1218 10.1481 14.3087L11.5636 17.7267C11.641 17.9135 11.8233 18.0354 12.0255 18.0354H36.3275C36.6626 18.0354 36.9028 18.3585 36.8063 18.6794L33.2078 30.644C33.1443 30.8553 32.9497 31 32.729 31H16.5003C16.2889 31 16.1003 30.867 16.0293 30.6678L13.7144 24.1678C13.5984 23.8422 13.8398 23.5 14.1854 23.5H24"
        stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </router-link>
</template>

<script>
import { EventBus } from '@/event-bus'


export default {
  name: 'ShoppingCartIcon',
  props: {
    selectedProducts: Array,
    selectedTank: Object,
  },
  data() {
    return {
      animate: false,
      productCount: 1
    }
  },
  created(){
    let that = this
    EventBus.$on('update_amount', () => {
      that.countProducts()
    })
  },
  mounted(){
    this.countProducts();
  },
  methods: {
    countProducts(){
      let count = 0
      for(let i = 0; i < this.selectedProducts.length; i++){
        count = count + this.selectedProducts[i].amount
      }
      this.productCount = count +1
    }
  },
  watch: {
    productCount(){
      let that = this;
      that.animate = true;
      setTimeout(() => {
        that.animate = false;
      }, 300)
    },
    selectedProducts(){
      this.countProducts()
    }
  }

}
</script>

<style>
  .myReefer-shopping-cart-icon{
    display: block;
  }
  .myreefer-shopping-cart-number{
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>