import MyReeferStart from './components/MyReeferStart.vue'
import TankSelection from './components/TankSelection.vue'
import MyReeferCart from './components/MyReeferCart.vue'
import MyReeferConfigurator from './components/MyReeferConfigurator.vue'

export default [
    { 
        path: '/:lang', 
        component: MyReeferStart,

    },
    { 
        path: '/:lang/tank-selection', 
        component: TankSelection,
    },
    { 
        path: '/:lang/configurator', 
        component: MyReeferConfigurator,
    },
    { 
        path: '/:lang/cart', 
        component: MyReeferCart,

    },
    {
      path: "*",
      redirect: "/en"
    }
]