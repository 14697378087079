<template>
  <div class="myReefer-total-watch col-12 border-box">
    <div :class="`${discountEventActive ? '' : 'lg-hide'} rsf-subtotal-container px2 lg-px0 pt3 lt-pt1 lg-pt2 lg-pr3 bg-color-16 lg-pb2 lt-pb1`"  v-if="selectedCountry.isocode2 != 'XX'">
      <div class="lg-px3">
        <div class="flex justify-between items-baseline mx-auto mb-xs">
          <span class="h6 color-black open-sans-semibold">{{ $t('rsf_subtotal') }}</span>
          <span class="h6 open-sans-semibold color-black">{{ subTotal }}</span>
        </div>
        <div class="flex justify-between items-baseline mx-auto mb-xs" v-if="myReeferDiscountAmountUnformatted > 0">
          <span class="h6 color-black open-sans-semibold subtotal-container-myreefer-discount">{{ bundleHead.has_discount == '1' ? $t('rsf_reefer_max_discount') : $t('rsf_myreefer_discount') }}</span>
          <span class="h6 open-sans-semibold color-3">-{{ myReeferDiscountAmount }}</span>
        </div>
        <div class="flex justify-between items-baseline mx-auto mb-xs" v-if="discountEventActive">
          <span class="h6 color-black open-sans-semibold subtotal-container-event-discount">{{ discountEvent.labelSubtotal}}</span>
          <span class="h6 open-sans-semibold color-3">-{{ eventDiscountAmount }}</span>
        </div>
      </div>
    </div>
    <div class="px2 lg-px0 py1 pt3 lg-pt1 lg-pr3 lt-pt0">
      <div v-if="selectedCountry.isocode2 != 'XX'">
        <div class="total-wtach-desktop">
          <div class="flex justify-between items-baseline mx-auto lg-px3 px0 pt1 gap4 lg-gap2">
            <div class="flex flex-wrap md-flex-row items-baseline gap1">
              <!-- fs 24 -> 20 -->
              <h2 class="h2 lt-h3 open-sans-bold">{{ $t('rsf_total') }}</h2>
              <span class="h6 open-sans-regular">{{ $t('rsf_total_plus') }}</span>
            </div>
            <div class="flex flex-wrap flex-row-reverse justify-start items-baseline gap1">
              <!-- fs 24 -> 20 -->
              <h2 class="h2 lt-h3 open-sans-bold">{{ total }}</h2>
              <!-- fs 16 -> 14 -->
              <span class="h5 lt-h6 open-sans-semibold color-3" v-show="totalSavings != ''">{{ totalSavings }} {{ $t('rsf_total_saved') }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- margin top 32 -> 16 -->
      <div class="myReefer-total-btn-wrapper flex flex-wrap gap2 mt3 lt-mt2 pt1 lg-pr3 pr0 mx-auto pb1 lt-pb0">
        <a v-if="isDealerVersion != 'true'" class="rsf-btn rsf-btn-primary color-white bg-color-1 h5 lt-h6"
          @click="toggleSendCopyForm">{{ $t('rsf_total_sendEmail') }}</a>
        <a v-if="selectedCountry.isocode2 != 'XX'" class="rsf-btn rsf-btn-primary color-white bg-color-1 h5 lt-h6"
          @click="toggleSendToDealerForm">{{ isDealerVersion != 'true' ? $t('rsf_total_sendDealer') : $t('rsf_order_now') }}</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Total',
  props: {
    toggleSendCopyForm: Function,
    toggleSendToDealerForm: Function,
    selectedCountry: Object,
    discountEventActive: Boolean, 
    discountEvent: Object,
    bundleHead: Object,
    total: String,
    subTotal: String,
    totalSavings: String,
    myReeferDiscountAmount: String, 
    eventDiscountAmount: String,
    myReeferDiscountAmountUnformatted: Number,
    isDealerVersion: String
    
  }
}
</script>

<style scoped>
.rsf-btn.rsf-btn-primary{
  height: 3.375rem;
}
</style>
