<template>
  <div :class="`myreefer-loader bg-color-white ${isLoading ? 'active' : ''}`">
    <div class="myreefer-loader-inner flex flex-column justify-center mx-auto items-center">
      <svg width="286" height="274" viewBox="0 0 286 274" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_432_20261" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="7" y="1" width="274"
          height="273">
          <circle cx="144.122" cy="137.179" r="136.179" fill="#CDF7F7" />
        </mask>
        <g mask="url(#mask0_432_20261)">
          <path d="M-6.82642 -12.8848H293.707V287.648H-6.82642V-12.8848Z" fill="#DEFBFF" />
          <rect x="90.4289" y="101.158" width="125.11" height="125.11" rx="11.4"
            transform="rotate(4.00822 90.4289 101.158)" fill="url(#paint0_linear_432_20261)" stroke="#617A92"
            stroke-width="1.2" />
          <path
            d="M176.617 218.931C243.507 211.663 263.671 219.303 278.314 228.484C290.438 236.086 293.492 304.166 268.097 302.886L-12.1003 288.761C-38.841 287.413 -18.0614 225.762 -3.39996 221.326C9.47968 217.429 46.3513 219.647 63.5445 222.259C92.3194 226.629 119.854 225.098 176.617 218.931Z"
            fill="#FFF6DF" stroke="#DAB39E" stroke-width="1.2" />
          <path
            d="M72.3765 217.574C61.6815 230.664 73.4465 231.854 91.6276 231.854H91.6328C109.811 231.853 119.434 231.853 121.573 227.093C123.712 222.333 120.504 215.194 114.087 212.814C107.67 210.434 83.0716 204.484 72.3765 217.574Z"
            fill="#E9C5BA" stroke="#A5614C" stroke-width="1.2" />
          <path
            d="M99.5342 226.074C91.5129 235.237 100.337 236.07 113.972 236.07H113.976C127.61 236.07 134.828 236.07 136.432 232.738C138.036 229.406 135.63 224.408 130.817 222.742C126.004 221.076 107.555 216.911 99.5342 226.074Z"
            fill="#E9C5BA" stroke="#A5614C" stroke-width="1.2" />
        </g>
        <path d="M81.6198 22.9565L74.7271 15.2021H81.6198L88.5126 22.9565H81.6198Z" fill="#C6C3C3" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <path d="M80.7578 24.6811L73.865 16.9268H80.7578L87.6506 24.6811H80.7578Z" fill="#565F78" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <rect x="55.7708" y="1.41797" width="25.8479" height="8.61597" fill="#778AA7" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <path d="M88.5117 23.8193L88.5117 49.6672L83.3421 49.6673L83.3421 23.8194L88.5117 23.8193Z" fill="#C6C3C3"
          stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M86.7883 23.8193L86.7883 49.6673L79.8955 49.6673L79.8955 23.8194L86.7883 23.8193Z" fill="#778AA7"
          stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M81.6194 1.41699L86.789 8.30977V16.9257L81.6194 10.033V1.41699Z" fill="#9CADC7" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <path d="M86.7892 16.927L81.6197 10.0342H55.7717L61.8029 16.927H86.7892Z" fill="#565F78" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M71.2793 15.7062C73.6812 15.7062 75.8696 15.5118 77.4698 15.1917C78.2658 15.0325 78.9423 14.8372 79.4319 14.6057C79.6755 14.4906 79.8996 14.3541 80.0697 14.1878C80.2407 14.0207 80.3953 13.7838 80.3953 13.483C80.3953 13.1822 80.2407 12.9452 80.0697 12.7781C79.8996 12.6118 79.6755 12.4754 79.4319 12.3602C78.9423 12.1287 78.2658 11.9334 77.4698 11.7742C75.8696 11.4541 73.6812 11.2598 71.2793 11.2598C68.8774 11.2598 66.689 11.4541 65.0888 11.7742C64.2929 11.9334 63.6163 12.1287 63.1267 12.3602C62.8831 12.4754 62.659 12.6118 62.4889 12.7781C62.3179 12.9452 62.1633 13.1822 62.1633 13.483C62.1633 13.7838 62.3179 14.0207 62.4889 14.1878C62.659 14.3541 62.8831 14.4906 63.1267 14.6057C63.6163 14.8372 64.2929 15.0325 65.0888 15.1917C66.689 15.5118 68.8774 15.7062 71.2793 15.7062Z"
          fill="#72B0FF" stroke="#0F67D6" />
        <ellipse cx="71.2809" cy="12.6214" rx="3.44639" ry="0.861598" fill="#D0E5FF" />
        <rect x="130.829" y="1.20215" width="26.3714" height="8.11429" fill="#778AA7" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <path d="M149.085 19.46L149.085 51.917L145.028 51.9171L145.028 19.46L149.085 19.46Z" fill="#C6C3C3"
          stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M149.085 17.4316L149.085 51.9173L140.971 51.9174L140.971 17.4317L149.085 17.4316Z" fill="#778AA7"
          stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M155.442 17.4307L157.2 9.31641H130.829L132.587 17.4307H155.442Z" fill="#565F78" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M145.029 15.9019C147.298 15.9019 149.369 15.6724 150.888 15.2928C151.643 15.1039 152.29 14.8711 152.76 14.5933C153.196 14.3353 153.643 13.9393 153.643 13.3733C153.643 12.8073 153.196 12.4112 152.76 12.1533C152.29 11.8755 151.643 11.6427 150.888 11.4538C149.369 11.0742 147.298 10.8447 145.029 10.8447C142.76 10.8447 140.688 11.0742 139.17 11.4538C138.415 11.6427 137.768 11.8755 137.298 12.1533C136.861 12.4112 136.415 12.8073 136.415 13.3733C136.415 13.9393 136.861 14.3353 137.298 14.5933C137.768 14.8711 138.415 15.1039 139.17 15.2928C140.688 15.6724 142.76 15.9019 145.029 15.9019Z"
          fill="#72B0FF" stroke="#0F67D6" />
        <ellipse cx="145.028" cy="12.359" rx="4.05714" ry="1.01429" fill="#D0E5FF" />
        <path d="M206.409 22.9565L213.302 15.2021H206.409L199.516 22.9565H206.409Z" fill="#C6C3C3" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <path d="M207.272 24.6811L214.165 16.9268H207.272L200.379 24.6811H207.272Z" fill="#565F78" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <rect width="25.8479" height="8.61597" transform="matrix(-1 0 0 1 232.258 1.41797)" fill="#778AA7"
          stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M199.517 23.8193L199.517 49.6672L204.686 49.6673L204.686 23.8194L199.517 23.8193Z" fill="#C6C3C3"
          stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M201.24 23.8193L201.24 49.6673L208.133 49.6673L208.133 23.8194L201.24 23.8193Z" fill="#778AA7"
          stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M206.409 1.41699L201.24 8.30977V16.9257L206.409 10.033V1.41699Z" fill="#9CADC7" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <path d="M201.24 16.927L206.409 10.0342H232.257L226.226 16.927H201.24Z" fill="#565F78" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M216.749 15.7062C214.347 15.7062 212.158 15.5118 210.558 15.1917C209.762 15.0325 209.086 14.8372 208.596 14.6057C208.352 14.4906 208.128 14.3541 207.958 14.1878C207.787 14.0207 207.633 13.7838 207.633 13.483C207.633 13.1822 207.787 12.9452 207.958 12.7781C208.128 12.6118 208.352 12.4754 208.596 12.3602C209.086 12.1287 209.762 11.9334 210.558 11.7742C212.158 11.4541 214.347 11.2598 216.749 11.2598C219.15 11.2598 221.339 11.4541 222.939 11.7742C223.735 11.9334 224.412 12.1287 224.901 12.3602C225.145 12.4754 225.369 12.6118 225.539 12.7781C225.71 12.9452 225.865 13.1822 225.865 13.483C225.865 13.7838 225.71 14.0207 225.539 14.1878C225.369 14.3541 225.145 14.4906 224.901 14.6057C224.412 14.8372 223.735 15.0325 222.939 15.1917C221.339 15.5118 219.15 15.7062 216.749 15.7062Z"
          fill="#72B0FF" stroke="#0F67D6" />
        <ellipse cx="3.44639" cy="0.861598" rx="3.44639" ry="0.861598" transform="matrix(-1 0 0 1 220.194 11.7598)"
          fill="#D0E5FF" />
        <rect x="29.8997" y="119.359" width="228.229" height="126.8" fill="white" stroke="#778AA7" />
        <mask id="mask1_432_20261" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="29" y="151" width="230"
          height="96">
          <rect x="29.8997" y="151.817" width="228.229" height="94.3429" fill="#ECFAFD" stroke="#778AA7" />
        </mask>
        <g mask="url(#mask1_432_20261)">
          <path opacity="0.15"
            d="M187.628 161.404C187.628 159.195 189.419 157.404 191.628 157.404H272.885C275.094 157.404 276.885 159.195 276.885 161.404V250.776C276.885 252.985 275.094 254.776 272.885 254.776H191.628C189.419 254.776 187.628 252.985 187.628 250.776V161.404Z"
            fill="#778AA7" />
          <path opacity="0.15"
            d="M90.2566 161.404C90.2566 159.195 92.0475 157.404 94.2566 157.404H175.514C177.723 157.404 179.514 159.195 179.514 161.404V250.776C179.514 252.985 177.723 254.776 175.514 254.776H94.2566C92.0475 254.776 90.2566 252.985 90.2566 250.776V161.404Z"
            fill="#778AA7" />
          <path opacity="0.15"
            d="M-7.11548 161.404C-7.11548 159.195 -5.32462 157.404 -3.11548 157.404H78.1417C80.3508 157.404 82.1417 159.195 82.1417 161.404V250.776C82.1417 252.985 80.3508 254.776 78.1417 254.776H-3.11548C-5.32462 254.776 -7.11548 252.985 -7.11548 250.776V161.404Z"
            fill="#778AA7" />
        </g>
        <rect x="104.457" y="120.888" width="2.02857" height="125.771" rx="0.88104" fill="#778AA7" />
        <rect x="179.514" y="120.888" width="2.02857" height="125.771" rx="0.88104" fill="#778AA7" />
        <path d="M31.4282 31.6318L57.7996 45.8318" stroke="#6B89D4" />
        <path d="M256.6 31.6318L230.228 45.8318" stroke="#6B89D4" />
        <path
          d="M29.8997 41.9245C29.8997 41.1205 30.6428 40.5022 31.4469 40.6459C35.748 41.415 46.0473 42.9957 58.0532 42.9957C65.9963 42.9957 69.9808 42.3036 73.5858 41.6115C73.7721 41.5757 73.9572 41.54 74.1417 41.5043C77.4988 40.8563 80.6652 40.2451 86.7068 40.2451C92.9656 40.2451 96.6171 40.9013 100.362 41.5743L100.575 41.6125C104.417 42.3026 108.402 42.9957 115.36 42.9957C122.205 42.9957 125.697 42.325 129.23 41.6463L129.411 41.6115C132.974 40.9275 136.645 40.2451 144.014 40.2451C151.384 40.2451 155.304 40.9278 158.991 41.612C159.123 41.6366 159.255 41.6611 159.387 41.6857C162.957 42.3498 166.429 42.9957 172.667 42.9957C178.804 42.9957 182.043 42.3706 185.423 41.7181C185.607 41.6826 185.792 41.647 185.977 41.6115C189.535 40.9283 193.455 40.2451 201.321 40.2451C209.188 40.2451 213.356 40.9285 217.039 41.612C217.305 41.6614 217.568 41.7108 217.83 41.7599C221.226 42.3962 224.425 42.9957 229.975 42.9957C239.368 42.9957 251.828 41.2787 256.659 40.5559C257.433 40.4402 258.128 41.0389 258.128 41.8192V120.388H29.8997V41.9245Z"
          fill="#72B0FF" stroke="#4D74CD" />
        <mask id="mask2_432_20261" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="29" y="39" width="230"
          height="80">
          <path
            d="M29.3997 41.9567C29.3997 40.8246 30.45 39.9751 31.5619 40.1876C35.8629 41.0093 46.1125 42.6886 58.0532 42.6886C73.8621 42.6886 73.8621 39.7451 86.7068 39.7451C99.5515 39.7451 101.528 42.6886 115.36 42.6886C129.193 42.6886 129.193 39.7451 144.014 39.7451C158.835 39.7451 159.823 42.6886 172.667 42.6886C185.512 42.6886 185.512 39.7451 201.321 39.7451C217.13 39.7451 218.118 42.6886 229.975 42.6886C239.314 42.6886 251.718 40.8624 256.565 40.0869C257.646 39.9139 258.628 40.748 258.628 41.8428V118.859H29.3997V41.9567Z"
            fill="url(#paint1_linear_432_20261)" />
        </mask>
        <g mask="url(#mask2_432_20261)">
          <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd"
            d="M-280.56 227.316L166.271 -212.282L195.216 -183.806L-251.615 255.792L-280.56 227.316ZM-229.916 277.155L216.915 -162.443L245.86 -133.967L-200.971 305.631L-229.916 277.155ZM267.567 -112.605L-179.264 326.993L-150.319 355.469L296.512 -84.1289L267.567 -112.605ZM-128.608 376.822L318.223 -62.7759L347.168 -34.2999L-99.6633 405.298L-128.608 376.822ZM368.875 -12.9392L-77.9565 426.659L-49.012 455.135L397.819 15.5368L368.875 -12.9392ZM-27.302 476.492L419.529 36.8944L448.474 65.3704L1.64249 504.968L-27.302 476.492ZM470.178 86.7228L23.3471 526.321L52.2916 554.797L499.123 115.199L470.178 86.7228Z"
            fill="white" />
        </g>
        <path
          d="M29.8997 30.4836C29.8997 30.2731 30.0703 30.1025 30.2807 30.1025H257.747C257.958 30.1025 258.128 30.2731 258.128 30.4836V120.388H29.8997V30.4836Z"
          fill="#72B0FF" fill-opacity="0.1" style="mix-blend-mode:multiply" />
        <path
          d="M29.8997 30.4836C29.8997 30.2731 30.0703 30.1025 30.2807 30.1025H257.747C257.958 30.1025 258.128 30.2731 258.128 30.4836V120.388H29.8997V30.4836Z"
          stroke="#0F67D6" />
        <rect x="141.471" y="208.616" width="19.2857" height="7.11429" fill="#565F78" stroke="#565F78"
          stroke-linejoin="round" />
        <path d="M141.664 207.617L143.36 202.531H160.756V207.617H141.664Z" fill="#A0A3AC" stroke="#565F78"
          stroke-linejoin="round" />
        <rect x="145.529" y="196.445" width="15.2286" height="7.11429" fill="#565F78" stroke="#565F78"
          stroke-linejoin="round" />
        <path d="M145.723 195.446L147.418 190.36H160.758V195.446H145.723Z" fill="#A0A3AC" stroke="#565F78"
          stroke-linejoin="round" />
        <path d="M100.4 184.788L106.486 177.688H134.886L136.915 184.788H100.4Z" fill="#565F78" stroke="#565F78" />
        <ellipse cx="113.587" cy="178.704" rx="6.08571" ry="1.01429" fill="white" />
        <ellipse cx="113.08" cy="181.746" rx="6.59286" ry="1.01429" fill="white" />
        <ellipse cx="127.788" cy="178.704" rx="6.08571" ry="1.01429" fill="white" />
        <ellipse cx="127.788" cy="181.746" rx="6.08571" ry="1.01429" fill="white" />
        <path
          d="M120.687 204.061C120.687 199.192 121.363 189.185 121.702 184.79H133.873C133.873 187.832 134.076 195.135 134.887 200.004C135.902 206.09 135.902 213.19 129.816 213.19C123.73 213.19 120.687 210.147 120.687 204.061Z"
          fill="#FAFAFB" />
        <path
          d="M107.502 210.147L106.487 185.804H118.659L117.644 210.147C117.644 210.485 116.63 211.161 112.573 211.161C108.516 211.161 107.502 210.485 107.502 210.147Z"
          fill="#FAFAFB" />
        <path
          d="M120.687 204.061C120.687 199.192 121.363 189.185 121.702 184.79H133.873C133.873 187.832 134.076 195.135 134.887 200.004C135.902 206.09 135.902 213.19 129.816 213.19C123.73 213.19 120.687 210.147 120.687 204.061Z"
          stroke="#565F78" />
        <path
          d="M107.502 210.147L106.487 185.804H118.659L117.644 210.147C117.644 210.485 116.63 211.161 112.573 211.161C108.516 211.161 107.502 210.485 107.502 210.147Z"
          stroke="#565F78" />
        <g opacity="0.2">
          <path opacity="0.6"
            d="M53.4356 63.2605C53.3967 63.2011 53.3456 63.1507 53.2857 63.1126L44.5918 57.5801C44.444 57.4861 44.2577 57.4762 44.1009 57.5542C43.944 57.6322 43.8393 57.7866 43.825 57.9612L43.1005 66.8131C43.0824 67.0347 43.2128 67.2417 43.4205 67.321L52.1144 70.6405C52.334 70.7243 52.5823 70.6439 52.7111 70.4473C52.7335 70.413 52.7781 70.358 52.8385 70.2836C53.2222 69.8104 54.2417 68.5533 54.2417 66.8539C54.2417 65.0064 53.7404 63.7261 53.4356 63.2605Z"
            fill="#565F78" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
          <circle cx="41.5713" cy="62.0601" r="4.55714" fill="#565F78" stroke="black" />
        </g>
        <g opacity="0.2">
          <path opacity="0.6"
            d="M234.593 63.2605C234.632 63.2011 234.683 63.1507 234.743 63.1126L243.437 57.5801C243.585 57.4861 243.771 57.4762 243.928 57.5542C244.085 57.6322 244.189 57.7866 244.204 57.9612L244.928 66.8131C244.946 67.0347 244.816 67.2417 244.608 67.321L235.914 70.6405C235.695 70.7243 235.446 70.6439 235.318 70.4473C235.295 70.413 235.25 70.358 235.19 70.2836C234.806 69.8104 233.787 68.5533 233.787 66.8539C233.787 65.0064 234.288 63.7261 234.593 63.2605Z"
            fill="#565F78" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
          <circle cx="4.05714" cy="4.05714" r="4.55714" transform="matrix(-1 0 0 1 250.514 58.0029)" fill="#565F78"
            stroke="black" />
        </g>
        <path
          d="M98.3713 167.488C98.3713 165.279 100.162 163.488 102.371 163.488H183.628C185.838 163.488 187.628 165.279 187.628 167.488V256.86C187.628 259.069 185.838 260.86 183.628 260.86H102.371C100.162 260.86 98.3713 259.069 98.3713 256.86V167.488Z"
          fill="#ECFAFD" stroke="#778AA7" />
        <path
          d="M128.758 242.753C128.758 241.753 131.259 239.835 132.509 239.002C133.968 238.168 137.512 236.5 140.013 236.5H155.02C156.896 236.5 160.772 236.626 161.273 237.126C161.898 237.751 161.273 240.252 160.022 242.128C158.772 244.004 157.521 244.629 154.395 244.629H131.259C128.758 244.629 128.758 244.004 128.758 242.753Z"
          fill="#FF3235" stroke="#FF3235" />
        <path d="M132.51 195.236V180.229H155.646V195.861L149.393 197.111V198.362H138.763V197.111L132.51 195.236Z"
          fill="#F7F9FB" />
        <path
          d="M133.135 227.751C133.635 224.749 137.095 208.992 138.763 201.488H149.393C150.643 207.324 153.395 220.372 154.395 225.875C155.396 231.377 155.229 237.338 155.02 239.631C154.604 240.256 151.894 241.507 144.39 241.507C136.887 241.507 133.344 240.673 132.51 240.256V233.378C132.51 232.753 132.635 230.752 133.135 227.751Z"
          fill="#F7F9FB" />
        <path d="M158.772 208.992H156.896V228.376H158.772V208.992Z" fill="#F7F9FB" />
        <path
          d="M130.009 209.617V201.488H135.636V209.617C135.428 209.826 134.636 210.243 133.135 210.243C131.635 210.243 130.426 209.826 130.009 209.617Z"
          fill="#F7F9FB" />
        <path d="M132.51 195.236V180.229H155.646V195.861L149.393 197.111V198.362H138.763V197.111L132.51 195.236Z"
          stroke="#565F78" />
        <path
          d="M133.135 227.751C133.635 224.749 137.095 208.992 138.763 201.488H149.393C150.643 207.324 153.395 220.372 154.395 225.875C155.396 231.377 155.229 237.338 155.02 239.631C154.604 240.256 151.894 241.507 144.39 241.507C136.887 241.507 133.344 240.673 132.51 240.256V233.378C132.51 232.753 132.635 230.752 133.135 227.751Z"
          stroke="#565F78" />
        <path d="M158.772 208.992H156.896V228.376H158.772V208.992Z" stroke="#565F78" />
        <path
          d="M130.009 209.617V201.488H135.636V209.617C135.428 209.826 134.636 210.243 133.135 210.243C131.635 210.243 130.426 209.826 130.009 209.617Z"
          stroke="#565F78" />
        <ellipse cx="144.389" cy="178.939" rx="11.8805" ry="1.25058" fill="#FF3235" stroke="#FF3235" />
        <rect x="140.014" y="208.367" width="2.50117" height="13.7564" fill="#FF3235" stroke="#FF3235" />
        <path d="M155.646 207.737L156.897 208.988H158.773L160.023 207.737V206.487H155.646V207.737Z" fill="#FF3235"
          stroke="#FF3235" />
        <rect x="131.258" y="200.863" width="2.50117" height="7.5035" fill="#FF3235" stroke="#FF3235" />
        <rect x="156.27" y="228.374" width="3.75175" height="10.0047" fill="#FF3235" stroke="#FF3235" />
        <path
          d="M132.51 198.982C131.009 199.482 130.217 200.441 130.009 200.858V201.483H150.643V198.357H138.763C137.304 198.357 134.011 198.482 132.51 198.982Z"
          fill="#FF3235" stroke="#FF3235" />
        <rect x="128.758" y="233.375" width="3.75175" height="3.75175" fill="#FF3235" stroke="#FF3235" />
        <path
          d="M132.584 210.204C132.584 211.038 132.628 215.721 131.628 220.724C130.627 225.726 130.635 231.084 130.635 234.002"
          stroke="#565F78" stroke-width="1.5" />
        <path
          d="M1 167.488C1 165.279 2.79086 163.488 5 163.488H86.2571C88.4663 163.488 90.2571 165.279 90.2571 167.488V256.86C90.2571 259.069 88.4663 260.86 86.2571 260.86H5C2.79086 260.86 1 259.069 1 256.86V167.488Z"
          fill="#ECFAFD" stroke="#778AA7" />
        <path d="M15.2007 206.403V218.258L43.6007 202.03V187.83L15.2007 206.403Z" fill="#D9E5F1" stroke="#778AA7"
          stroke-linecap="round" stroke-linejoin="round" />
        <path d="M76.0575 201.226L43.6003 187.831V202.031L76.0575 214.202V201.226Z" fill="#778AA7" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <path d="M46.9522 228.402L15.2007 218.259L43.6007 202.031L76.0578 214.202L46.9522 228.402Z" fill="#565F78"
          stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M61.3575 215.216C61.3575 216.867 59.9038 218.517 57.1899 219.783C54.5166 221.031 50.7883 221.816 46.6432 221.816C42.4981 221.816 38.7699 221.031 36.0966 219.783C33.3827 218.517 31.929 216.867 31.929 215.216C31.929 213.565 33.3827 211.915 36.0966 210.648C38.7699 209.401 42.4981 208.616 46.6432 208.616C50.7883 208.616 54.5166 209.401 57.1899 210.648C59.9038 211.915 61.3575 213.565 61.3575 215.216Z"
          fill="#72B0FF" stroke="#4A74CD" />
        <ellipse cx="46.6435" cy="213.187" rx="7.1" ry="3.04286" fill="#D0E5FF" />
        <path
          d="M195.743 167.488C195.743 165.279 197.534 163.488 199.743 163.488H281C283.209 163.488 285 165.279 285 167.488V256.86C285 259.069 283.209 260.86 281 260.86H199.743C197.534 260.86 195.743 259.069 195.743 256.86V167.488Z"
          fill="#ECFAFD" stroke="#778AA7" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M209.944 200.203C209.944 200.093 209.854 200.003 209.744 200.003H207.239C206.904 200.003 206.577 200.099 206.294 200.279C206.04 200.44 205.887 200.72 205.887 201.021V213.217C205.887 213.499 206.028 213.763 206.263 213.919C206.54 214.105 206.867 214.203 207.2 214.203H209.744C209.854 214.203 209.944 214.114 209.944 214.003V200.203Z"
          fill="#778AA7" stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M270.801 200.203C270.801 200.093 270.89 200.003 271.001 200.003H273.505C273.84 200.003 274.168 200.099 274.451 200.279C274.704 200.44 274.858 200.72 274.858 201.021V213.217C274.858 213.499 274.717 213.763 274.482 213.919C274.204 214.105 273.878 214.203 273.544 214.203H271.001C270.89 214.203 270.801 214.114 270.801 214.003V200.203Z"
          fill="#778AA7" stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M209.944 200.203C209.944 200.093 209.854 200.003 209.744 200.003H207.239C206.904 200.003 206.577 200.099 206.294 200.279C206.04 200.44 205.887 200.72 205.887 201.021V206.089H209.744C209.854 206.089 209.944 206 209.944 205.889V200.203Z"
          fill="#D9E5F1" stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M270.801 200.203C270.801 200.093 270.89 200.003 271.001 200.003H273.505C273.84 200.003 274.168 200.099 274.451 200.279C274.704 200.44 274.858 200.72 274.858 201.021V206.089H271.001C270.89 206.089 270.801 206 270.801 205.889V200.203Z"
          fill="#D9E5F1" stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M205.887 202.032L203.858 202.818V211.507L205.887 212.175"
          stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M274.859 202.032L276.887 202.818V211.507L274.859 212.175"
          stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <rect x="209.944" y="202.032" width="18.2571" height="10.1429" fill="#778AA7" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <rect width="18.2571" height="10.1429" transform="matrix(-1 0 0 1 270.801 202.032)" fill="#778AA7"
          stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <rect x="211.972" y="208.118" width="2.02857" height="4.05714" rx="1" fill="#565F78" />
        <rect width="2.02857" height="4.05714" rx="1" transform="matrix(-1 0 0 1 268.772 208.118)" fill="#565F78" />
        <rect width="18.2571" height="6.08571" transform="matrix(1 0 0 -1 209.944 208.118)" fill="#D9E5F1"
          stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <rect x="270.801" y="208.118" width="18.2571" height="6.08571" transform="rotate(180 270.801 208.118)"
          fill="#D9E5F1" stroke="#565F78" stroke-linecap="round" stroke-linejoin="round" />
        <rect x="211.972" y="202.032" width="2.02857" height="4.05714" rx="1" fill="#565F78" />
        <rect width="2.02857" height="4.05714" rx="1" transform="matrix(-1 0 0 1 268.772 202.032)" fill="#565F78" />
        <rect x="216.029" y="208.118" width="2.02857" height="4.05714" rx="1" fill="#565F78" />
        <rect width="2.02857" height="4.05714" rx="1" transform="matrix(-1 0 0 1 264.715 208.118)" fill="#565F78" />
        <rect x="216.029" y="202.032" width="2.02857" height="4.05714" rx="1" fill="#565F78" />
        <rect width="2.02857" height="4.05714" rx="1" transform="matrix(-1 0 0 1 264.715 202.032)" fill="#565F78" />
        <rect x="220.086" y="208.118" width="2.02857" height="4.05714" rx="1" fill="#565F78" />
        <rect width="2.02857" height="4.05714" rx="1" transform="matrix(-1 0 0 1 260.658 208.118)" fill="#565F78" />
        <rect x="220.086" y="202.032" width="2.02857" height="4.05714" rx="1" fill="#565F78" />
        <rect width="2.02857" height="4.05714" rx="1" transform="matrix(-1 0 0 1 260.658 202.032)" fill="#565F78" />
        <rect x="224.144" y="208.118" width="2.02857" height="4.05714" rx="1" fill="#565F78" />
        <rect width="2.02857" height="4.05714" rx="1" transform="matrix(-1 0 0 1 256.601 208.118)" fill="#565F78" />
        <rect x="224.144" y="202.032" width="2.02857" height="4.05714" rx="1" fill="#565F78" />
        <rect width="2.02857" height="4.05714" rx="1" transform="matrix(-1 0 0 1 256.601 202.032)" fill="#565F78" />
        <rect x="230.23" y="200.003" width="20.2857" height="14.2" fill="#778AA7" stroke="#565F78" stroke-linecap="round"
          stroke-linejoin="round" />
        <rect width="20.2857" height="6.08571" transform="matrix(1 0 0 -1 230.23 206.09)" fill="#D9E5F1" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <path d="M228.201 202.032L230.229 200.003V214.203L228.201 212.175V202.032Z" fill="#D9E5F1" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <path d="M252.543 202.032L250.515 200.003V214.203L252.543 212.175V202.032Z" fill="#D9E5F1" stroke="#565F78"
          stroke-linecap="round" stroke-linejoin="round" />
        <defs>
          <linearGradient id="paint0_linear_432_20261" x1="138.695" y1="139.08" x2="155.92" y2="199.56"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="1" stop-color="#F4F6F7" />
          </linearGradient>
          <linearGradient id="paint1_linear_432_20261" x1="144.014" y1="39.7451" x2="144.014" y2="126.578"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#9BB7FF" />
            <stop offset="1" stop-color="#6F6CFE" />
          </linearGradient>
        </defs>
      </svg>
      <h3 class="h2 helvetica-bold mt2 mb1 max-width-3 center px3 border-box">{{ loaderHeading }}</h3>
      <!-- <p class="h5 open-sans-regular max-width-3 center px2 border-box">{{ loaderSubheading }}</p> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'Loader',
  props: {
    isLoading: Boolean,
    loaderHeading: String,
    loaderSubheading: String,
  }
}
</script>

<style>
  .myreefer-loader {
    display: grid;
    place-items: center;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all .5s ease;
    z-index: 10;
    height: 100vh;
    transition-delay: 2s;
  }

  @media(max-width: 1024px){
    .myreefer-loader{
      transition-delay: 1s;
    }
  }

  .myreefer-loader.active {
    opacity: 1;
  }
</style>