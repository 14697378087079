<template>
  <div class="rsf-categroy-wrapper mb5 col-12 max-width-5" v-if="visibleProducts > 0">
    <!-- <h3 class="helvetica-bold h2 mb3 color-black center hide sm-show">{{$t('rsf_category_heading_build_your_own')}}</h3> -->
    <div :class="`rsf-category-grid grid-1-col md-grid-2-col gap2 justify-center ${otherBundles.length == 0 ? 'md-grid-4-col grid-2-col' : 'md-grid-2-col grid-1-col'}`">
      <TankCard
        v-for="product in bundle"
        :product="product"
        :selectedProducts="selectedProducts"
        :toggleSelectedProduct="toggleSelectedProduct"
        :key="product.id"
        v-if="product.mainProductInfo.LengthMetric >= computedLowCap && product.mainProductInfo.LengthMetric <= computedHiCap"
        :selectTank="selectTank"
        :formatCurrency="formatCurrency"
        :selectedColor="selectedColor"
        :selectedCountry="selectedCountry"
        :measurement="measurement"
        :isDiscountedBundle="isDiscountedBundle"
        :showTag="showTag"
      />
    </div>
  </div>
</template>

<script>
import TankCard from './TankCard'

export default { 
  name: 'CategoryGrid',
  props: {
    bundle: Array,
    selectedProducts: Array,
    toggleSelectedProduct: Function,
    computedLowCap: Number,
    computedHiCap: Number,
    selectTank: Function,
    formatCurrency: Function,
    selectedColor: String,
    selectedCountry: Object,
    measurement: String,
    isDiscountedBundle: Boolean,
    otherBundles: Array,
    showTag: Boolean
  },
  components: {
    TankCard
  },
  computed: {
    visibleProducts () {
      let visibleProducts = 0
      for(let i = 0; i < this.bundle.length; i++){
        if(Number(this.bundle[i].mainProductInfo.LengthMetric) >= this.computedLowCap && Number(this.bundle[i].mainProductInfo.LengthMetric) <= this.computedHiCap){
          visibleProducts ++
        }
      }
      return visibleProducts
    }
  }
}
</script>
