<template>
  <div class="flex-grow relative">
    <img 
      :class="`aquarium-preview-img configurator-image ${overlayImg.alwaysOnTopImage == '1' ? 'z4' : 'z3'}`" 
      @load="onOverlayImgLoad($event)" 
      v-for="overlayImg in imageOverlays" 
      v-if="showOverlayImgs"
      :src="overlayImg.specialImagePath" 
    />

    <img 
      class="aquarium-preview-img configurator-image z2 bg-color-white"
      @load="onImgLoad($event)" 
      :src="baseImg" 
    />
  </div>
</template>

<script>

export default {
    name: "DynamicTank",
    props: {
        selectedProducts: Array,
        selectedTank: Object,
        selectedColor: String,
        imageOverlays: Array,
        loadingImages: Boolean,
        fetchedDetailedData: Boolean,
        cartView: Boolean
    },
    data() {
        return {
            showOverlayImgs: false
        };
    },
    methods: {
        onImgLoad(e) {
            e.target.classList.add("img-loaded");
            this.showOverlayImgs = true;
        },
        onOverlayImgLoad(e) {
            e.target.classList.add("img-loaded");
        }
    },
    computed: {
        baseImg() {
            if (this.fetchedDetailedData || this.cartView) {
                if (this.selectedTank.MainProduct1 && this.selectedTank.MainProduct2)
                    if (this.selectedColor == this.selectedTank.MainProduct1.Color) {
                        return this.selectedTank.MainProduct1.configurator_baseimage;
                    }
                if (this.selectedColor == this.selectedTank.MainProduct2.Color) {
                    return this.selectedTank.MainProduct2.configurator_baseimage;
                }
            }
        },
    },
}
</script>

<style>
.myReefer-dynamic-tank-wrapper {
  flex-grow: 1;
}
.aquarium-preview-img{
  position:absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  object-fit: contain;
  width: 100%; 
  height: 100%;
}
</style>
