<template>
  <div class="myreefer-discount-event-countdown">
    <p class="center lg-h4 h6 bold" v-html="timerString"></p>
  </div>
</template>

<script>


export default {
  name: 'Countdown',
  props: {
    startDate: String,
    endDate: String
  },
  data(){
    return{
      timerString: this.$t('rsf_myreefer_discount_event_expires_in')
    }
  },
  mounted() {
    let countDownDate = new Date(this.endDate);
    this.countDown(countDownDate.getTime());
  },
  methods: {
    countDown(countDownDate) {
      let that = this
      
      // Update the count down every 1 second
      var x = setInterval(function () {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

        that.timerString = `
          ${that.$t('rsf_myreefer_discount_event_expires_in')}
          <span class="color-1">
            ${days == 1 ? days +  ' ' + that.$t('rsf_myreefer_discount_event_expires_in_days_singular') : days > 0 ? days +  ' ' + that.$t('rsf_myreefer_discount_event_expires_in_days_plural') : ''} 
            ${hours == 1 ? hours +  ' ' + that.$t('rsf_myreefer_discount_event_expires_in_hours_singular') : hours > 0 ? hours +  ' ' + that.$t('rsf_myreefer_discount_event_expires_in_hours_plural') : ''} 
            ${minutes == 1 ? minutes +  ' ' + that.$t('rsf_myreefer_discount_event_expires_in_min_singular') : minutes > 0 ? minutes +  ' ' + that.$t('rsf_myreefer_discount_event_expires_in_min_plural') : ''} 
          </span>`

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          that.timerString = 'Sale expired'
        }
      }, 1000);
    }
  }
}
</script>