<template>
  <div
    class="flex-grow bg-color-11 pt3 border-box pb4 flex flex-column justify-center items-center px2">
    <div class="mx-auto flex-column-reverse lg-flex gap3 justify-between items-center max-width-6 col-12 border-box">
      <div class="col-12 max-width-3 lg-pr5 border-box">
        <div class="col-12 max-width-3 mx-auto lg-mx0">
          <div class="myreefer-start-desktop-title">
            <h1 class=" max-width-1 helvetica-bold h1 lg-mx0 mx-auto mb2 lg-left-align center myreefer-start-title"
              v-html="isDealerVersion != 'true' ? this.$t('rsf_myReefer_title') : this.dealerHeadline"></h1>
            <p class="p max-width-1 lg-pr2 border-box mb3 lg-mx0 mx-auto lg-left-align center h4">
              {{ $t('rsf_myReefer_subtitle') }}</p>
          </div>
          <div class="myreefer-start-mobile-title">
            <h1 class=" max-width-1 helvetica-bold h1 lg-mx0 mx-auto mb2 lg-left-align center myreefer-start-title"
              v-html="this.$t('rsf_myReefer_title_mobile')"></h1>
            <p class="p max-width-1 lg-pr2 border-box mb2 lg-mx0 mx-auto lg-left-align center h4">
              {{ $t('rsf_myReefer_subtitle_mobile') }}</p>
          </div>
          <CountrySelect v-if="isDealerVersion != 'true'" :updateCountry="updateCountry" :siteLanguage="siteLanguage" />
          <router-link
            v-if="isDealerVersion != 'true'"
            :class="`rsf-btn rsf-btn-primary mt3 bg-color-1 color-white lg-ml-auto lg-mr0 mx-auto ${selectedCountry ? '' : 'bg-color-inactive pointer-events-none'}`"
            :to="'/' + this.$i18n.locale + '/tank-selection'">{{ $t('rsf_myReefer_start') }}
          </router-link>
          <router-link
            v-if="isDealerVersion == 'true'"
            :class="`rsf-btn rsf-btn-primary mt3 bg-color-1 color-white lg-mr0 lg-ml0 mx-auto`"
            :to="'/' + this.$i18n.locale + '/tank-selection'">{{ $t('rsf_myReefer_continue') }}
          </router-link>
        </div>
      </div>
      <div class="col-12 relative mx-auto gridbox myreefer-start-img-container">
          <img class="myReefer-start-img myreefer-start-img-base block gridbox-element block mx-auto" :src="isDealerVersion == 'true' ? dealerHeroImg : 'https://static.redseafish.com/wp-content/uploads/2023/02/14133323/myReefer_start_img-1.png'" />
          <!-- Discount Flag-img should have the same dimensions as base-img -->
          <img 
            v-if="discountEventActive" 
            class="myReefer-start-img myreefer-discount-event-flag block gridbox-element block mx-auto" 
            :src="discountEvent.imagesPromoImageS3Path" 
          />
      </div>
    </div>
  </div>
</template>

<script>

import CountrySelect from './CountrySelect.vue'
import Loader from './Loader.vue';
import axios from 'axios';
export default {
  name: 'MyReeferStart',
  props: {
    updateCountry: Function,
    selectedCountry: Object,
    siteLanguage: String,
    discountEventActive: Boolean, 
    discountEvent: Object,
    isDealerVersion: String,
    singleDealer: Object
  },
  components: {
    CountrySelect,
    Loader
  },
  data(){ 
    return {
      dealerHeadline: '',
      dealerHeroImg: '',
    }
  },
  created(){
    if(this.isDealerVersion == 'true'){
      axios.get(process.env.VUE_APP_RSF_API + `/public-api/settings/myreefer/${this.siteLanguage}/json`)
        .then(response => {
          if(response.data.settings['my-reefer-dealer-startpage-headline'].includes('{{myreeferStoreName}}') && this.singleDealer.myreeferStoreName){
            this.dealerHeadline = response.data.settings['my-reefer-dealer-startpage-headline'].replace('{{myreeferStoreName}}', this.singleDealer.myreeferStoreName);
          }else{
            this.dealerHeadline = this.$t('rsf_myReefer_title_dealer');
          }
          this.dealerHeroImg = response.data.settings['my-reefer-startpage-hero-img'];
        })
        .catch(err => {
          console.log(err);
        })
    }
  },
}
</script>
