<template>
  <div class="mx-auto col-12 center relative mb3">
    <div v-if="step == 1" class="range-value-wrapper border-box sm-px0">
        <div class="range-value"><span></span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 50 : 20}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 75 : 30}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 100 : 39}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 125 : 49}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 150 : 59}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 175 : 69}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 200 : 79}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 210 : 83}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value"><span></span></div>
    </div>
    <div v-if="step == 1.5" class="range-value-wrapper mobile-values border-box px2 sm-px0">
        <div class="range-value"><span></span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 50 : 20}}<span style="display: inline-block">{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 90 : 39}}<span style="display: inline-block">{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 130 : 59}}<span style="display: inline-block">{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 170 : 79}}<span style="display: inline-block">{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-regular h6">{{measurement == 'metric' ? 210 : 83}}<span style="display: inline-block">{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value"><span></span></div>
    </div>
    <div id="range-slider" class="range-slider flat" data-ticks-position='top' style='--min:1; --max:10; --value-a:-1; --value-b:10;'>   
      <input v-model="lowerCap" type="range" min="1" max="10" value="1"
        oninput="this.parentNode.style.setProperty('--value-a',this.value);" :step="step">
      <input v-model="upperCap" type="range" min="1" max="10" value="10"
        oninput="this.parentNode.style.setProperty('--value-b',this.value);" :step="step">
      <div class='range-slider__progress'></div>
  </div>

  </div>
</template>

<script>

export default {
  name: 'FilterSlider',
  props: {
    updateUpperCap: Function, 
    updateLowerCap: Function,
    measurement: String
  },  
  data(){
    return {
      lowerCap: '1', 
      upperCap: '10',
      step: 1
    }
  },  
  watch: {
    upperCap (newValue, oldValue){
      if(Number(newValue) == Number(this.lowerCap) || Number(newValue) < Number(this.lowerCap)){
        this.upperCap = oldValue
        let slider = document.getElementById('range-slider');
        slider.style.setProperty('--value-b', oldValue)
      }
      this.updateUpperCap(this.upperCap)
    },
    lowerCap (newValue, oldValue){
      if(Number(newValue) == Number(this.upperCap)){
        this.lowerCap = oldValue
        let slider = document.getElementById('range-slider');
        slider.style.setProperty('--value-a', oldValue)
      }
      if(Number(newValue) > Number(this.upperCap)){
        this.lowerCap = oldValue
        let slider = document.getElementById('range-slider');
        slider.style.setProperty('--value-a', oldValue)
      }
      this.updateLowerCap(this.lowerCap)
    }
  },
  mounted(){
    if(window.innerWidth > 1023){
      this.step = 1;
    }else{
      this.step = 1.5;
    }
    window.addEventListener("resize", (event) => {
      if(window.innerWidth > 1023){
        this.step = 1;
      }else{
        this.step = 1.5;
      }
    });
  }
}
</script>