<template>
    <div class="h100 flex flex-column col-12 myreefer-configurator-layout-container relative">

        <!-- LOADER -->
        <slot name="loader"></slot>


        <!-- DISCOUNT BANNER -->
        <slot name="top-banner"></slot>

        
        <!-- COLUMN SPLIT -->
        <div class="lg-flex bg-color-white flex-grow myreefer-configurator-columns-wrapper mx-auto col-12">

            <slot name="mobile-head"></slot>

            <!-- AQAURIUM COLUMN -->
            <div class="aquarium-preview-container bg-color-white lg-col-7 lg-flex lg-flex-column flex-grow">
                <div class="flex-grow aquarium-preview-inner flex flex-column pt3 md-pt0">
                    <slot name="left-column-aquarium-preview"></slot>
                </div>
                <div class="aqaurium-preview-info-line">
                    <slot name="left-column-foot"></slot>
                </div>
            </div>

            <!-- PRODUCTS COLUMN -->
            <div class="myreefer-products-column lg-col-5 lg-flex lg-flex-column flex-grow">
                <slot name="right-column-head"></slot>
                <div id="myreefer-scroll-container" class="vertical-overflow">
                    <slot name="overflow-container-inner"></slot>
                </div>
                <slot name="right-column-foot"></slot>
            </div>

        </div>

        <slot name="layout-foot"></slot>
    </div>
</template>
  
<script>
import Loader from './Loader.vue';

export default {
    name: 'MyReeferConfiguratorLayout',
    components: {Loader}
}
</script>

<style>

html,
body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.wp-site-blocks{
    flex-grow: 1; 
    display: flex; 
    flex-direction:column;
}

.entry-content{
    flex-grow: 1;
    display: flex; 
    flex-direction: column; 
}

.myreefer-wizard {
    flex-grow: 1;
    display: flex;
    flex-direction: column; 
    width: 100%; 
}

.myreefer-configurator-columns-wrapper{
    max-width: var(--width-7);
}

.flex-grow {
    flex-grow: 1;
}

.vertical-overflow {
    overflow-y: auto;
    box-sizing: border-box;
    flex: 1 1 auto;
    height: 1px; 
    cursor: pointer;
    /* needs a fixed height for proper fley-grow to work */
}

.vertical-overflow > div{
    cursor: default;
    min-height: 100%; 
    box-sizing: border-box;
}

/* .vertical-overflow::-webkit-scrollbar {
  display: none;
}
 .vertical-overflow {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

.vertical-overflow::-webkit-scrollbar-track
{
    box-shadow: inset 0 0 14px 14px transparent;
    border: solid 4px transparent;
}

.vertical-overflow::-webkit-scrollbar
{
    width: 32px;
}

.vertical-overflow::-webkit-scrollbar-thumb
{
  box-shadow: inset 0 0 14px 14px var(--rsf-2022-color-13);
  border: solid 12px transparent;
  border-radius: 16px;
}

/* set button(top and bottom of the scrollbar) */
.vertical-overflow::-webkit-scrollbar-button {
  display: none;
}

.inner-overflow {
    background: repeating-linear-gradient(#e66465, #e66465 20px, #9198e5 20px, #9198e5 25px);
    height: 1000px;
}

.mobile-cart{
    position: absolute; 
    right: 1rem; 
    top: .25rem;
}

/* .fixed-cart{
    position: fixed; 
    top: 0rem;
    right: .5rem; 
    z-index: 99999999999999;
    transform: scale(0.8)
} */

.fixed-cart{
    position: fixed; 
    top: calc(var(--top-menu-height) + 1rem);
    right: 1rem; 
    z-index: 9999;
}

@media(min-width: 1025px){
    .myreefer-core-mobile-heading{
        display: none;
    }
}

@media(max-width: 1024px){
    .vertical-overflow{
        overflow: none;
        height: auto;
    }
    .aquarium-preview-container{
        position: sticky;
        top: var(--top-menu-height);
        z-index: 2;
    }
    .aquarium-preview-inner{
        height: 35vh;
    }
    .aqaurium-preview-info-line{
        display: none;
    }
    .myreefer-products-column.h100{
        height: auto;
    }
}


</style>