<template>
  <div :class="`myreefer-popup story_atom stb_modal border-box p2 ${showSendToDealerForm ? 'stb_modal_active' : ''}`"
    @click="toggleSendToDealerForm(); resetForm()">
    <div @click.stop="" class="content-wrapper max-width-3 col-12 py5 px2 bg-color-white relative border-box">

      <div :class="`compare_loader flex flex-column items-center z3 ${workingForm ? 'loading' : ''} `"
        style="border-radius: 8px">
        <div class="lds-roller mx-auto">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h2 class="center h3 color-black mt2">{{ $t('rsf_form_sending_loading') }}</h2>
      </div>

      <div :class="`rsf-form-notice ${formSuccess ? 'show' : ''}`">
        <div class="flex flex-column items-center">
          <h2 class="center mb3 open-sans-bold h1 px2 border-box">{{$t('rsf_form_sent_dealer_success')}}</h2>
          <a class="rsf-btn rsf-btn-primary color-white bg-color-1" @click="toggleSendToDealerForm(); resetForm()">{{$t('rsf_form_sent_dealer_success_close_btn')}}</a>
        </div>
      </div>

      <div :class="`rsf-form-notice ${formFailure ? 'show' : ''}`">
        <div class="flex flex-column items-center">
          <h2 class="center mb3 open-sans-bold h1 px2 border-box">{{$t('rsf_form_dealer_data_sent_failure')}}</h2>
          <a class="rsf-btn rsf-btn-primary color-white bg-color-1" @click="toggleSendToDealerForm(); resetForm()">{{$t('rsf_form_sent_dealer_failure_close_btn')}}</a>
        </div>
      </div>


      <form @submit="sendDealerForm" :class="`col-12 max-width-2 mx-auto ${workingForm ? 'form-hide' : ''}`">
        <h3 v-if="autoOpened" class="open-sans-bold h2 center mb3">{{ $t('rsf_copy_form_sent_successfully') }}</h3>
        <h2 :class="`open-sans-bold h1 center ${isDealerVersion != 'true' ? 'mb2' : 'mb3'}`">{{ isDealerVersion != 'true' ? $t('rsf_saveHass') : $t('rsf_order_form') }}</h2>
        <h3 v-if="isDealerVersion != 'true'" class="open-sans-regular h3 mb3 center" style="line-height: 1.2; font-weight: 500">{{ $t('rsf_sendSpecsDealer') }}</h3>

        <input required class="rsf-input col-12 border-box mb2" v-model="firstname"
          type="text"
          :placeholder="$t('rsf_form_placeholder_firstname')" />
        <input required class="rsf-input col-12 border-box mb2" v-model="lastname"
          type="text"
          :placeholder="$t('rsf_form_placeholder_lastname')" />
        <input required class="rsf-input col-12 border-box mb2" v-model="email"
          type="email"
          :placeholder="$t('rsf_form_placeholder_email')" />
        <input class="rsf-input col-12 border-box mb2" v-model="phone"
          type="text"
          :placeholder="$t('rsf_form_placeholder_phone')" />
        <textarea class="rsf-textarea col-12 border-box mb2" v-model="message" rows="4"
          :placeholder="$t('rsf_form_placeholder_message')" />
        <!-- <CountrySelect class="mb2" :updateCountry="updateCountry" /> -->
        <StateSelect v-if="isDealerVersion != 'true'" v-show="selectedCountry.has_state_selection == '1'" :selectedCountry="selectedCountry" :updateState="updateState" class="mb2" />
        <DealerSelect v-if="isDealerVersion != 'true'" :updateDealer="updateDealer" :selectedCountry="selectedCountry" :selectedState="selectedState"/>
        <p class="color-14 h6 open-sans-regular pt2">{{ $t('rsf_send_dealer_form_note') }}</p>
        <button type="submit" ref="send_dealer_submit_btn" class="rsf-btn rsf-btn-primary mx-auto bg-color-1 color-white mt3 h5">{{ isDealerVersion != 'true' ? $t('rsf_sendDealer') : $t('rsf_send')  }}</button>
      </form>

      <a class="rsf-btn myReefer-lightbox-close-btn" @click="toggleSendToDealerForm(); resetForm()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="19.6867" height="2" rx="1"
            transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.15625 1.58594)" fill="black" />
          <rect x="15.4902" y="1.58594" width="19.6867" height="2" rx="1" transform="rotate(135 15.4902 1.58594)"
            fill="black" />
        </svg>
      </a>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import CountrySelect from './CountrySelect.vue';
import DealerSelect from './DealerSelect.vue';
import StateSelect from './StateSelect.vue'

export default {
  name: 'SendToDealerForm',
  props: {
    showSendToDealerForm: Boolean,
    toggleSendToDealerForm: Function,
    selectedProducts: Array,
    selectedTank: Object,
    selectedColor: String,
    selectedCountry: Object,
    updateCountry: Function,
    updateDealer: Function,
    selectedDealer: Object,
    siteLanguage: String,
    autoOpened: Boolean,
    discountEvent: Object,
    isDealerVersion: String,
    singleDealer: Object


  },
  components: {
    CountrySelect,
    DealerSelect,
    StateSelect
  },
  data() {
    return {
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      dealer: {
        name: 'Jeff',
        email: ''
      },
      country: '',
      message: this.$t('rsf_send_to_dealer_default_message'),
      subscribeToNewsletter: false,
      workingForm: false,
      formSuccess: false,
      formFailure: false,
      selectedState: {},
    }
  },
  methods: {
    updateState(state) {
      this.selectedState = state;
    },
    resetState(){
      this.selectedState = {};
    },
    sendDealerForm(e) {
      e.preventDefault();

      this.$refs['send_dealer_submit_btn'].style.pointerEvents = 'none';
      setTimeout(() => {
        that.$refs['send_dealer_submit_btn'].style.pointerEvents = 'all';
      }, 1500);

      let that = this;
      let payloadProducts = [];
      let payloadTank = {};

      this.workingForm = true;

      payloadTank.category = 'Aquarium';

      if (this.selectedColor == 'White') {
        payloadTank.RNumber = this.selectedTank.MainProduct1.RNumber;
        payloadTank.amount = 1;
      }
      if (this.selectedColor == 'Black') {
        payloadTank.RNumber = this.selectedTank.MainProduct2.RNumber;
        payloadTank.amount = 1;
      }

      payloadProducts.push(payloadTank);

      for (let i = 0; i < this.selectedProducts.length; i++) {
        let product = {}
        product.RNumber = this.selectedProducts[i].RNumber;
        product.amount = this.selectedProducts[i].amount;
        product.isPreselected = this.selectedProducts[i].isPreselected;
        product.category = this.selectedProducts[i].Category;
        payloadProducts.push(product);
      }

      let url = '';
      if(this.isDealerVersion == 'true'){
        url = "/public-api/myreefer/send-configuration/to/dealerversion/json"
      }else{
        url = "/public-api/myreefer/send-configuration/to/dealer/json"
      }

      axios
        .post(process.env.VUE_APP_RSF_API + url, {
          bundle_id: this.selectedTank.id,
          customer_firstname: this.firstname,
          customer_lastname: this.lastname,
          customer_email: this.email,
          customer_phonenumber: this.phone,
          customer_state: this.selectedCountry,
          dealer_name: this.isDealerVersion == 'true' ? this.singleDealer.dealerName : this.selectedDealer.name,
          dealer_id: this.isDealerVersion == 'true' ? this.singleDealer.dealerSlug : this.selectedDealer.id,
          comment: this.message,
          products: payloadProducts,
          lang: this.siteLanguage,
          isocode: this.selectedCountry.isocode2,
          discountEvent: this.discountEvent
        })
        .then(function (response) {
          // that.toggleSendToDealerForm();
          // window.alert(this.$t('rsf_form_dealer_data_sent_success'))
          that.workingForm = false;
          that.formSuccess = true;
        })
        .catch(function (error) {
          // window.alert(this.$t('rsf_form_dealer_data_sent_failure'))
          that.workingForm = false;
          that.formFailure = true;
        });
    },
    resetForm() {
      setTimeout(() => {
        this.workingForm = false;
        this.formSuccess = false;
        this.formFailure = false;
      }, 500)

    }
  }
}
</script>