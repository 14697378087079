<template>
  <div :class="`myreefer-popup story_atom stb_modal border-box p2 ${showModal ? 'stb_modal_active' : ''}`" @click="toggleModal">
    <div v-show="ledNeedsMounts" @click.stop="" class="content-wrapper max-width-3 col-12 py5 px2 bg-color-white border-box" style="border-radius: 8px">
      <h2 class="open-sans-bold h1 center mb2 px2">{{modalHeadingMounts}}</h2>
      <p class="open-sans-regular h4 max-width-1 center mx-auto mb3 color-13">{{modalMessageMounts}}</p>
      <div class="flex flex-wrap items-center gap2 justify-center">
        <a class="rsf-btn rsf-btn-primary bg-color-1 color-white" @click="toggleModal">{{$t('rsf_no_mounts_go_back')}}</a>
        <a v-if="useFunction == 1" class="rsf-btn rsf-btn-secondary bg-color-white color-1" @click="proceedFunction">{{ $t('rsf_no_mounts_proceed') }}</a>
        <a v-if="useFunction == 2" class="rsf-btn rsf-btn-secondary bg-color-white color-1" @click="proceedFunction2">{{ $t('rsf_no_mounts_proceed') }}</a>
      </div>
    </div>
    <div v-show="ledsNeedTrays" @click.stop="" class="content-wrapper max-width-3 col-12 py5 px2 bg-color-white border-box" style="border-radius: 8px">
      <h2 class="open-sans-bold h1 center mb2 px2">{{modalHeadingTrays}}</h2>
      <p class="open-sans-regular h4 max-width-1 center mx-auto mb3 color-13">{{modalMessageTrays}}</p>
      <div class="flex flex-wrap items-center gap2 justify-center">
        <a class="rsf-btn rsf-btn-primary bg-color-1 color-white" @click="toggleModal">{{$t('rsf_no_mounts_go_back')}}</a>
        <a v-if="useFunction == 1" class="rsf-btn rsf-btn-secondary bg-color-white color-1" @click="proceedFunction">{{ $t('rsf_no_mounts_proceed') }}</a>
        <a v-if="useFunction == 2" class="rsf-btn rsf-btn-secondary bg-color-white color-1" @click="proceedFunction2">{{ $t('rsf_no_mounts_proceed') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'

export default {
  name: 'AskForMountsLightbox',
  props: {
    showModal: Boolean,
    toggleModal: Function,
    modalMessageMounts: String,
    modalHeadingMounts: String,
    modalMessageTrays: String,
    modalHeadingTrays: String,
    proceedFunction: Function,
    proceedFunction2: Function,
    useFunction: Number,
    ledNeedsMounts: Boolean,
    ledsNeedTrays: Boolean
  }
}
</script>
