<template>

    <div :class="`rsf-compare-product-card rsf-cabinet-color-card rsf-card-interaction-animation bg-color-white pointer pb3 ${isSelected ? 'card-selected' : ''}`" @click="updateCabinetColor(product.color)"
      style="border-radius: 4px">
      <img class="col-12 contain p2 border-box" :src="product.thumbnail"/>
      <div class="px1 border-box">
        <div style="min-height: 60px">
          <h4 class="h3 helvetica-bold color-black center">{{product.title}}</h4>
        </div>

        <!-- Toggle Select -->
        <button
          :class="`myReefer-product-select-button block mx-auto relative pointer ${isSelected ? 'color-3' : 'color-1'}`"
          >
          <svg v-if="!isSelected" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.6148 7.41483H8.98517V1.78517C8.98517 1.57693 8.90245 1.37722 8.7552 1.22997C8.60795 1.08272 8.40824 1 8.2 1C7.99176 1 7.79205 1.08272 7.6448 1.22997C7.49755 1.37722 7.41483 1.57693 7.41483 1.78517V7.41483H1.78517C1.57693 7.41483 1.37722 7.49755 1.22997 7.6448C1.08272 7.79205 1 7.99176 1 8.2C1 8.40824 1.08272 8.60795 1.22997 8.7552C1.37722 8.90245 1.57693 8.98517 1.78517 8.98517H7.41483V14.6148C7.41483 14.8231 7.49755 15.0228 7.6448 15.17C7.79205 15.3173 7.99176 15.4 8.2 15.4C8.40824 15.4 8.60795 15.3173 8.7552 15.17C8.90245 15.0228 8.98517 14.8231 8.98517 14.6148V8.98517H14.6148C14.8231 8.98517 15.0228 8.90245 15.17 8.7552C15.3173 8.60795 15.4 8.40824 15.4 8.2C15.4 7.99176 15.3173 7.79205 15.17 7.6448C15.0228 7.49755 14.8231 7.41483 14.6148 7.41483Z"
              fill="#1867FF" />
          </svg>
          <svg v-if="isSelected" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8L5.875 13L14 3" stroke="#00BF7A" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>

          <span :class="`${(siteLanguage == 'de' || siteLanguage == 'fr') ? 'mobile-select-btn-small' : ''} h5 open-sans-bold`">{{ isSelected ? $t('rsf_product_selected') : $t('rsf_product_select') }}</span>
        </button>
      </div>
    </div>
</template>

<script>

export default {
  name: 'CabinetColorCard',
  props: {
    updateCabinetColor: Function,
    selectedColor: String,
    product: Object,
    siteLanguage: String
  },
  computed: {
    isSelected(){
      if(this.selectedColor == this.product.color){
        return true
      }else{
        return false
      }
    }
  },

}
</script>