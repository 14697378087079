<template>
  <div class="rsf-select-wrapper relative z1">
    <svg :style="`transform: rotate(180deg) translateY(50%)`" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <select @change="updateState(selectedState)" v-model="selectedState"
      :class="`rsf-select open-sans-regular pointer ${selectedState === '' ? 'color-13' : ''}`">
      <option isselected disabled hidden :value="''">{{ selectedCountry.isocode2 == 'GB' ? $t('rsf_state_select_prompt_county') : $t('rsf_state_select_prompt') }}</option>
      <option v-for="state in states" :value="state">{{ state.state }}</option>
    </select>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'StateSelect',
  props: {
    updateState: Function,
    selectedCountry: Object
  },
  data() {
    return {
      states: [],
      selectedState: ''
    }
  },
  mounted() {
    this.getStateData();
  },
  watch: {
    'selectedCountry.isocode2': function () {
      this.getStateData();
    }
  },
  methods: {
    getStateData() {
      let that = this
      axios
        .get(`${process.env.VUE_APP_RSF_API}/public-api/dealers/list-dealer-states-by-country/${this.selectedCountry.isocode2}/json`)
        .then(function (response) {
            that.states = response.data.data;
            // that.selectedState = response.data.data[0];

            //edge case for JP
            if(response.data.data.length == 0){
              that.states = [{state: ' '}];
              that.selectedState = {state: ' '}
            }
            // that.updateState(that.selectedState);
        })
        .catch(function (error) {
        });
    }
  }
}
</script>

<style>

</style>
