<template>
  <div :id="`anchor-${category.categoryname}`"
    :class="`${show ? 'bg-color-11' : 'myreefer-product-section-mobile-bg-color-white'}`"
    class="myreefer-productsection relative border-box lg-px0 px2">
    <div
      :class="`flex justify-between pointer py2 ${showSelectedCategoryProducts ? '' : 'items-center'} ${show && !showSelectedCategoryProducts ? 'first-section-padding' : ''}`"
      @click.stop="toggleProductSection">
      <div :class="`flex gap2 ${showSelectedCategoryProducts ? '' : 'items-center'}`">
        <img :class="`product-section-thumbnail ${show ? 'hide-thumbnail-on-mobile' : ''} ${imgLoaded ? 'show-product-section-thumbnail' : ''}`"
          :src="category.categoryimage_s3path" @load="imgLoaded = true" />

        <!-- SHOW SELECTED PRODUCTS IN CATEGROY HEAD -->
        <div v-if="showSelectedCategoryProducts">
          <h2
            :class="`${show ? 'helvetica-semibold mobile-category-head-bigger-fontsize' : 'helvetica-roman product-section-title'} color-black h4`">
            {{ category.categoryname }}
          </h2>
          <p v-for="product in selectedProducts" v-if="product.Category == category.categoryname && product.amount > 0"
            :key="product.id" class="h6 open-sans-regular color-13">
            {{ product.amount }}x {{ product.Model }}
          </p>
        </div>

        <!-- NORMAL HEADLINE -->
        <div v-if="!showSelectedCategoryProducts">
          <h2
            :class="`${show ? 'helvetica-bold mobile-category-head-bigger-fontsize' : 'helvetica-roman product-section-title'} color-black h4`">
            {{ category.categoryname }}
          </h2>
          <!-- SHOW COLOR WHEN CABINATECOLORSECTION -->
          <p class="h6 color-13 open-sans-regular" v-if="showSelectedColor">
            {{ selectedColor == 'Black' ?
              $t('rsf_myReeferCore_colorBlack') : $t('rsf_myReeferCore_colorWhite')
            }}
          </p>
        </div>

      </div>
      <div class="flex gap1" v-if="showSelectedCategoryProducts">
        <div v-if="selectedCountry.isocode2 != 'XX'">
          <div class="flex items-baseline gap1">
            <p class="crossed color-6 h6 open-sans-regular">
              {{
                formatCurrency(selectedProducts[0].prices.currency, totalInCategoryWithoutReduce)
              }}
              </p>
            <p class="open-sans-semibold">{{ formatCurrency(selectedProducts[0].prices.currency, totalInCategory) }}</p>
          </div>
        </div>
        <div class="">
          <svg v-if="show" class="svg-color-1" :style="`transform: rotate(${show ? '0' : '180deg'})`" width="24"
            height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <svg v-if="!show" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_432_19469)">
              <path
                d="M17.2158 2.23939C17.3134 2.14176 17.4717 2.14176 17.5694 2.23939L21.7621 6.43213C21.8597 6.52976 21.8597 6.68805 21.7621 6.78568L9.9627 18.5851C9.93235 18.6154 9.8947 18.6374 9.85338 18.649L4.02859 20.2811C3.84084 20.3337 3.66781 20.1607 3.72041 19.9729L5.35246 14.1481C5.36404 14.1068 5.38606 14.0691 5.41641 14.0388L17.2158 2.23939Z"
                stroke="#1867FF" stroke-width="1.5" stroke-linejoin="round" />
              <path d="M6 14.5L10 18.5" stroke="#1867FF" stroke-width="1.5" stroke-linecap="round" />
            </g>
            <defs>
              <clipPath id="clip0_432_19469">
                <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>

        </div>
      </div>
      <div v-if="!showSelectedCategoryProducts" class="">
        <svg v-if="!show" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_432_19469)">
            <path
              d="M17.2158 2.23939C17.3134 2.14176 17.4717 2.14176 17.5694 2.23939L21.7621 6.43213C21.8597 6.52976 21.8597 6.68805 21.7621 6.78568L9.9627 18.5851C9.93235 18.6154 9.8947 18.6374 9.85338 18.649L4.02859 20.2811C3.84084 20.3337 3.66781 20.1607 3.72041 19.9729L5.35246 14.1481C5.36404 14.1068 5.38606 14.0691 5.41641 14.0388L17.2158 2.23939Z"
              stroke="#1867FF" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M6 14.5L10 18.5" stroke="#1867FF" stroke-width="1.5" stroke-linecap="round" />
          </g>
          <defs>
            <clipPath id="clip0_432_19469">
              <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
        <svg v-if="show" class="svg-color-1" :style="`transform: rotate(${show ? '0' : '180deg'})`" width="24"
          height="24" viewBox="0 0 24 24" fill="none">
          <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

    </div>
    <div v-show="show" class="product-section-padding">
      <div class="myReefer-prdouct-section-product-grid grid-2-col gap2 mx-auto py2">
        <!-- CARDS INSERTED HERE -->
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus';
export default {
  name: 'ProductSection',
  props: {
    selectedProducts: Array,
    category: Object,
    selectedColor: String,
    showSelectedColor: Boolean,
    formatCurrency: Function,
    selectedCountry: Object,
    discountEventActive: Boolean, 
    discountEvent: Object,
    bundleHead: Object,

  },
  data() {
    return {
      show: false,
      imgLoaded: false,
      totalInCategory: 0,
      totalInCategoryWithoutReduce: 0,
    }
  },
  created() {
    EventBus.$on('close-section', () => {
      this.show = false;
    })
    EventBus.$on('update_amount', () => {
      this.computeTotalInCategoryWithoutReduce()
      this.computeTotalInCategory()
    })
  },
  mounted(){
    this.computeTotalInCategoryWithoutReduce()
    this.computeTotalInCategory()
  },
  methods: {
    toggleProductSection(e) {

      if (this.show == false) {
        EventBus.$emit('close-section');
        this.show = true;
      } else {
        EventBus.$emit('close-section');
      }

      if(window.innerWidth > 1023){
        setTimeout(() => {
          const element = document.getElementById('anchor-' + this.category.categoryname);
          const container = document.getElementById('myreefer-scroll-container');

          const elementRect = element.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();

          const distanceToTop = elementRect.top - containerRect.top;
          container.scrollTop = container.scrollTop + distanceToTop;
        }, 10);
      }

      if(window.innerWidth <= 1024){
        setTimeout(() => {
        const element = document.getElementById('anchor-' + this.category.categoryname);
        const container = document.getElementById('myreefer-scroll-container');
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const navHeight = Number(document.documentElement.style.getPropertyValue('--top-menu-height').split('px')[0]);
        const distanceToTop = elementRect.top - containerRect.top;


        window.scroll(0, (navHeight + distanceToTop))
      }, 10);


      }

    },
    computeTotalInCategory() {
      let productTotal = 0;
      for (let i = 0; i < this.selectedProducts.length; i++) {
        if (this.selectedProducts[i].Category == this.category.categoryname) {
          if (this.selectedProducts[i].prices.isDiscounted) {
            productTotal = productTotal + parseFloat(this.selectedProducts[i].prices.discountprice) * this.selectedProducts[i].amount;
          } else {
            productTotal = productTotal + parseFloat(this.selectedProducts[i].prices.retailprice) * this.selectedProducts[i].amount;
          }
        }
      }

      // if(this.discountEventActive){
      //   productTotal = productTotal - ((productTotal * this.discountEvent.discountinpercent) / 100)
      // }

      this.totalInCategory = productTotal

    },
    computeTotalInCategoryWithoutReduce() {
      let productTotal = 0;

      for (let i = 0; i < this.selectedProducts.length; i++) {
        if (this.selectedProducts[i].Category == this.category.categoryname) {
          productTotal = productTotal + parseFloat(this.selectedProducts[i].prices.retailprice) * this.selectedProducts[i].amount;
        }
      }

      this.totalInCategoryWithoutReduce = productTotal
    },
  },
  computed: {
    showSelectedCategoryProducts() {
      for (let i = 0; i < this.selectedProducts.length; i++) {
        if (this.selectedProducts[i].Category == this.category.categoryname) {
          return true
        }
      }
      return false
    }
  },
  watch: {
    selectedProducts(){
      this.computeTotalInCategoryWithoutReduce()
      this.computeTotalInCategory()
    }
  }
}
</script>
