<template>
  <div class="flex mx-auto items-center w-fit-content mb3 gap2">
    <span :class="isOn ? `open-sans-bold` : ''">{{$t('rsf_cabinet_color_white')}}</span>
  <div class="rsf-compare-unit-switch myreefer-swicth relative flex gap1 justify-betweem pointer" style="height: 34px" @click="isOn = !isOn; changeColor()">
    <div class="switch-thumb" :style="`left:${isOn ? '2px' : '28px'}` "  >
    </div>
  </div>
  <span :class="!isOn ? `open-sans-bold` : ''">{{$t('rsf_cabinet_color_black')}}</span>
</div>
</template>

<script>

export default { 
  name: 'CustomSwitch',
  data() {
    return {
      isOn: false
    }
  },
  props: {
    selectedColor: String,
    updateCabinetColor: Function
  },
  mounted(){
    if(this.selectedColor == 'White'){
      this.isOn = true
    }else{
      this.isOn = false
    }
  },  
  methods: {
    changeColor(){
      if(this.isOn){
        this.updateCabinetColor('White')
      }else{
        this.updateCabinetColor('Black')
      }
    }
  }
}
</script>
