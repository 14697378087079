<template>
    <div class="p2 lg-p0 bg-color-white myreefer-discount-event-banner-small">
    <div class="myreefer-discount-event-banner-box relative">
      <img class="myreefer-discount-event-banner-bg-img" :src="discountEvent.imagesBackgroundImageS3Path"  /> 
      <div class="p1 py2 border-box relative z2">
        <h3 class="h5 color-white center regular">{{ discountEvent.labelBanner1 }}</h3>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DiscountEventBannerSmall',
  props: {
    discountEvent: Object
  }
}
</script>