<template>
  <div :class="`myreefer-popup story_atom stb_modal border-box p2 ${showSendCopyForm ? 'stb_modal_active' : ''}`" @click="toggleSendCopyForm(); resetForm()">
    <div @click.stop="" class="content-wrapper max-width-3 col-12 py5 px2 bg-color-white relative border-box">

      <div :class="`compare_loader flex flex-column items-center z3 bg-color-white ${workingForm ? 'loading' : ''} `"
        style="border-radius: 8px">
        <div class="lds-roller mx-auto">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h2 class="center h3 color-black mt2">{{ $t('rsf_form_sending_loading') }}</h2>
      </div>

      <div :class="`rsf-form-notice ${formSuccess ? 'show' : ''}`">
        <div class="flex flex-column items-center">
          <h2 class="center mb3 open-sans-bold h1">{{$t('rsf_form_sent_self_success')}}</h2>
          <a class="rsf-btn rsf-btn-primary color-white bg-color-1" @click="toggleSendCopyForm(); resetForm()">{{$t('rsf_form_sent_self_success_close_btn')}}</a>
        </div>
      </div>

      <div :class="`rsf-form-notice ${formFailure ? 'show' : ''}`">
        <div class="flex flex-column items-center">
          <h2 class="center mb3 open-sans-bold h1 px2">{{$t('rsf_form_self_data_sent_failure')}}</h2>
          <a class="rsf-btn rsf-btn-primary color-white bg-color-1" @click="toggleSendCopyForm(); resetForm()">{{$t('rsf_form_sent_self_failure_close_btn')}}</a>
        </div>
      </div>

      <form  @submit="sendCopyForm" :class="`col-12 max-width-2 mx-auto ${workingForm ? 'form-hide' : ''}`">
        <h2 class="open-sans-bold h1 center mb3">{{ $t('rsf_sendCopy') }}</h2>
        <input required type="email" class="rsf-input col-12 border-box mb2" v-model="email"
          :placeholder="$t('rsf_form_placeholder_email')" />
        <div class="flex items-center gap1 mb2">
          
          <label class="checkbox-input-wrapper flex items-center pointer gap1 open-sans-regular h6 color-14" for="myreefer-subscribe-to-newsletter">
            <input id="myreefer-subscribe-to-newsletter" v-model="subscribeToNewsletter" class="w-fit-content" type="checkbox"  name="subscribe-to-newsletter">
              {{ $t('rsf_subscribeNews') }}
            <span class="checkbox-checkmark"></span>
          </label>
        </div>
        <input 
          v-show="subscribeToNewsletter" 
          v-model="name" 
          :required="subscribeToNewsletter ? true : false" 
          type="text" 
          class="rsf-input col-12 border-box mb2"
          :placeholder="$t('rsf_form_placeholder_fullname')"
        >
        <input 
          v-show="subscribeToNewsletter" 
          v-model="lastname" 
          :required="subscribeToNewsletter ? true : false" 
          type="text" 
          class="rsf-input col-12 border-box mb2"
          :placeholder="$t('rsf_form_placeholder_lastname')"
        >
        <CountrySelect 
          v-show="subscribeToNewsletter" 
          :useGeneralCountriesList="true"
          :selectedCountry2="newsletterCountry"
          :myReeferSelectedCountry="selectedCountry"
          :siteLanguage="siteLanguage"
          :updateCountry="updateNewsletterCountry"
        />
        <button 
          type="submit" 
          ref="send_copy_submit_btn" 
          :class="`rsf-btn rsf-btn-primary mx-auto bg-color-1 color-white mt3 h5 ${subscribeToNewsletter && newsletterCountry.isocode2 == '' ? 'inactive' : ''}`"
         >{{ $t('rsf_send') }}</button>
      </form>

      <a type="submit" class="rsf-btn myReefer-lightbox-close-btn" @click="toggleSendCopyForm(); resetForm()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="19.6867" height="2" rx="1"
            transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.15625 1.58594)" fill="black" />
          <rect x="15.4902" y="1.58594" width="19.6867" height="2" rx="1" transform="rotate(135 15.4902 1.58594)"
            fill="black" />
        </svg>
      </a>
    </div>

  </div>
</template>

<script>

import axios from 'axios'

import CountrySelect from './CountrySelect.vue';

export default {
  name: 'SendCopyForm',
  props: {
    showSendCopyForm: Boolean,
    toggleSendCopyForm: Function,
    selectedProducts: Array,
    selectedTank: Object,
    selectedColor: String,
    siteLanguage: String,
    selectedCountry: Object,
    autoOpenDealerForm: Function,
    updateCountry: Function,
    discountEvent: Object,
  },
  components: {
    CountrySelect
  },
  data() {
    return {
      email: '',
      name: '',
      lastname: '',
      subscribeToNewsletter: false,
      workingForm: false,
      formSuccess: false,
      formFailure: false,
      newsletterCountry: {
        isocode2: ''
      },


    }
  },
  methods: {
    updateNewsletterCountry(v){
      this.newsletterCountry = v
    },
    sendCopyForm(e) {
      e.preventDefault();

      this.$refs['send_copy_submit_btn'].style.pointerEvents = 'none';
      setTimeout(() => {
        that.$refs['send_copy_submit_btn'].style.pointerEvents = 'all';
      }, 1500);

      let that = this;
      let payloadProducts = [];
      let payloadTank = {};
      this.workingForm = true;

      payloadTank.category = 'Aquarium';

      if (this.selectedColor == 'White') {
        payloadTank.RNumber = this.selectedTank.MainProduct1.RNumber;
        payloadTank.amount = 1;
      }
      if (this.selectedColor == 'Black') {
        payloadTank.RNumber = this.selectedTank.MainProduct2.RNumber;
        payloadTank.amount = 1;
      }

      payloadProducts.push(payloadTank);

      for (let i = 0; i < this.selectedProducts.length; i++) {
        let product = {}
        product.RNumber = this.selectedProducts[i].RNumber;
        product.isPreselected = this.selectedProducts[i].isPreselected
        product.amount = this.selectedProducts[i].amount;
        product.category = this.selectedProducts[i].Category;
        payloadProducts.push(product);
      }

      let browserLang = navigator.language || navigator.userLanguage;

      axios
        .post(process.env.VUE_APP_RSF_API + "/public-api/myreefer/send-configuration/to/bla/json", {
          bundle_id: this.selectedTank.id,
          customer_firstname: this.name,
          customer_lastname: this.lastname,
          recieve_newsletter: this.subscribeToNewsletter,
          customer_state: this.newsletterCountry,
          customer_email: this.email,
          products: payloadProducts,
          lang: this.siteLanguage,
          isocode: this.newsletterCountry.isocode2,
          discountEvent: this.discountEvent,
          browserLang: browserLang
        })
        .then(function (response) {
          that.workingForm = false;
          if(that.selectedCountry.isocode2 != 'XX'){
            that.toggleSendCopyForm();
            that.resetForm();
            that.autoOpenDealerForm();
          }
          if(that.selectedCountry.isocode2 == 'XX'){
            console.log('edge-case other country')
            that.formSuccess = true;
          }
          
        })
        .catch(function (error) {
          that.workingForm = false;
          that.formFailure = true;
        });
    },
    resetForm() {
      setTimeout(() => {
        this.workingForm = false;
        this.formSuccess = false;
        this.formFailure = false;
      }, 500)

    }
  }
}
</script>

<style scoped>
  .rsf-btn.inactive{
    opacity: 0.5; 
    pointer-events: none;
  }
</style>
