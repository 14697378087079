<template>
  <MyReeferConfiguratorLayout>

    <div slot="top-banner">
      <DiscountEventBannerSmall v-if="discountEventActive" :discountEvent="discountEvent" />
    </div>

    <DynamicTank 
      slot="left-column-aquarium-preview"
      :selectedProducts="selectedProducts" 
      :selectedColor="selectedColor" 
      :selectedTank="selectedTank"
      :imageOverlays="imageOverlays" 
      :loadingImages="loadingImages" 
      :fetchedDetailedData="fetchedDetailedData" 
    />

    <p slot="left-column-foot" class="myReefer-terms-and-cons-line center color-13 open-sans-regular my3">{{ $t('rsf_myReeferCore_act') }}
      <a class="color-1"  :href="'https://g1.redseafish.com/' + this.siteLanguage + '/terms-and-conditions-myreefer-promotion/'">
        {{ $t('rsf_myReeferCore_terms') }}
      </a>
    </p>


    <div slot="right-column-head">
      <div class="myreefer-cart-heading-mobile-hide flex justify-between items-center border-box col-12 py3 lg-px3">
          <h2 class="h3">{{ $t('rsf_myReeferCart_your') }} {{ selectedTank.BundleName }}</h2>
          <router-link :to="'/' + this.$i18n.locale + '/configurator'" class="rsf-btn rsf-btn-tertiary color-1 flex items-center">
            <svg class="svg-color-1" :style="`transform: rotate(-90deg)`" width="24" height="24" viewBox="0 0 24 24"
              fill="none">
              <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            {{ $t('rsf_myReeferCart_back') }}
          </router-link>
        </div>
        <div class="h1px col-12 bg-color-12"></div>
    </div>


    <div slot="overflow-container-inner" class="myReefer-product-section lg-px3 lg-pr3 px0 lg-pb3">
      <div class="myreefer-cart-section pb2 pt3">
        <h3 class="mb1 pl2 lg-pl0">{{$t('rsf_cart_aquarium_section_heading')}}</h3>
        <div class="myReefer-cart-item flex items-baseline justify-between mb2 pt2">
          <div class="lg-flex items-baseline cart-item-name cart-item-bundle-name">
            <h3 class="open-sans-regular h5 pl2 lg-pl0">{{ selectedTank.BundleName }}</h3>
            <h4 class="h6 lg-h5 open-sans-regular color-13 pl2 lg-pl0"
              v-html="selectedColor ? `<span class='px1 color-black open-sans-regular cart-item-info-divider'>|</span>` + (selectedColor == 'Black' ? $t('rsf_myReeferCore_colorBlack') : $t('rsf_myReeferCore_colorWhite')) : ''"></h4>
          </div>
          <div v-show="selectedCountry.isocode2 != 'XX'" class="pr2 lg-pr0">
            <ItemPrice 
              v-if="bundleHead.has_discount != '1'"
              :currency="selectedTank.mainProductPrices.currency"
              :amount="1"
              :discountEvent="discountEvent"
              :discountEventActive="discountEventActive"
              :discountPrice="selectedTank.mainProductPrices.discountprice"
              :retailPrice="selectedTank.mainProductPrices.retailprice"
              :formatCurrency="formatCurrency"

            />
          </div>
        </div>
      </div>
      <div class="px2 lg-px0">
        <div 
          class="myreefer-cart-section pb2 pt3" 
          v-if="hasSelectedItems(category)" 
          v-for="category in productCategories" 
          :key="category.id"
        >
          <h3 class="mb2">{{category.categoryname}}</h3>
          <CartItem 
            v-if="isInSelectedProducts(product)"
            v-for="product in category.products" 
            :key="product.id" 
            :selectedProducts="selectedProducts"
            :updateSelectedProducts="updateSelectedProducts" 
            :product="product" 
            :formatCurrency="formatCurrency"
            :productCategories="productCategories" 
            :openModal="openModal" 
            :computeImageOverlays="computeImageOverlays"
            :selectedCountry="selectedCountry" 
            :category="category" 
            :discountEvent="discountEvent"
            :discountEventActive="discountEventActive"
            :bundleHead="bundleHead"
          />
        </div>
      </div>
      <div class="lg-px0 px2 mt3 hide lg-block" v-if="!discountEventActive && selectedCountry.isocode2 != 'XX'">
        <div class="flex justify-between items-baseline mx-auto">
          <span class="h6 color-black open-sans-regular">{{ $t('rsf_subtotal') }}</span>
          <span class="h5 open-sans-regular color-black">{{ subTotal }}</span>
        </div>
        <div class="flex justify-between items-baseline mx-auto mb0" v-if="myReeferDiscountAmountUnformatted > 0">
          <span class="h6 color-3 open-sans-regular">{{ bundleHead.has_discount == '1' ? $t('rsf_reefer_max_discount') : $t('rsf_myreefer_discount') }}</span>
          <span class="h5 open-sans-regular color-3">-{{ myReeferDiscountAmount }}</span>
        </div>
        <div class="flex justify-between items-baseline mx-auto" v-if="discountEventActive">
          <span class="h6 color-3 open-sans-regular">{{ discountEvent.labelSubtotal}}</span>
          <span class="h5 open-sans-regular color-3">-{{ eventDiscountAmount }}</span>
        </div>
      </div>
    </div>
    
    <Total 
      slot="right-column-foot"
      :selectedProducts="selectedProducts" 
      :selectedTank="selectedTank"
      :selectedColor="selectedColor" 
      :toggleSendCopyForm="toggleSendCopyForm"
      :toggleSendToDealerForm="toggleSendToDealerForm" 
      :formatCurrency="formatCurrency" 
      :selectedCountry="selectedCountry" 
      :discountEventActive="discountEventActive"
      :discountEvent="discountEvent"
      :bundleHead="bundleHead"
      :total="total"
      :subTotal="subTotal" 
      :myReeferDiscountAmount="myReeferDiscountAmount"
      :myReeferDiscountAmountUnformatted="myReeferDiscountAmountUnformatted"
      :eventDiscountAmount="eventDiscountAmount"
      :totalSavings="totalSavings" 
      :isDealerVersion="isDealerVersion"
    />

    <div slot="layout-foot" class="myreefer-cart-back-btn flex justify-center items-center border-box col-12 py2 bg-color-white">
      <router-link :to="'/' + this.$i18n.locale + '/configurator'" class="rsf-btn rsf-btn-tertiary color-1 py1 flex items-center pr2 block mx-auto">
        <svg class="svg-color-1" :style="`transform: rotate(-90deg)`" width="24" height="24" viewBox="0 0 24 24"
          fill="none">
          <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        {{ $t('rsf_myReeferCart_back') }}
      </router-link>
    </div>

  </MyReeferConfiguratorLayout>
</template>

<script>
import MyReeferConfiguratorLayout from './MyReefer_Configurator_Layout.vue'
import Total from './Total.vue'
import CabinetColorCard from './CabinetColorCard.vue'
import DynamicTank from './DynamicTank.vue'
import CartItem from './CartItem.vue'
import DiscountEventBannerSmall from './DiscountEventBannerSmall.vue'
import ItemPrice from './ItemPrice.vue'

export default {
  name: 'MyReeferCartView',
  data() {
    return {
      selectedProductsSortedByCategory: [],
      sectionPadding: 0
    }
  },
  props: {
    selectedProducts: Array,
    selectedTank: Object,
    updateSelectedProducts: Function,
    toggleSendCopyForm: Function,
    toggleSendToDealerForm: Function,
    updateCabinetColor: Function,
    selectedColor: String,
    fetchedDetailedData: Boolean,
    imageOverlays: Array,
    formatCurrency: Function,
    loadingImages: Boolean,
    productCategories: Array,
    openModal: Function,
    computeImageOverlays: Function,
    selectedCountry: Object,
    productCategories: Array,
    siteLanguage: String,
    selectedCountry: Object,
    discountEventActive: Boolean, 
    discountEvent: Object,
    bundleHead: Object,

    total: String,
    subTotal: String,
    totalSavings: String,
    myReeferDiscountAmount: String, 
    myReeferDiscountAmountUnformatted: Number, 
    eventDiscountAmount: String,

    isDealerVersion: String
  },
  components: {
    Total,
    CabinetColorCard,
    DynamicTank,
    CartItem,
    MyReeferConfiguratorLayout,
    DiscountEventBannerSmall,
    ItemPrice
},
  mounted() {
    if (!this.selectedTank.id) {
      this.$router.push('/')
    }
    window.scrollTo(0,0)

  },
  methods: {
    isInSelectedProducts(product){
      let result = this.selectedProducts.find(({ RNumber }) => RNumber === product.RNumber );
      if(result){return true;}
      return false;
    },
    hasSelectedItems(category){
      let counter = 0
      for(let i = 0; i < this.selectedProducts.length; i++){
        let result = category.products.find(({ RNumber }) => RNumber === this.selectedProducts[i].RNumber );
        if(result){
          counter++
        }
      }
      if(counter > 0){return true;}
      return false;
    }
  }
}
</script>